import _ from "lodash";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../../../Context/Context";
import FlightService from "../../../../Service/FlightService";
import {
  CABIN_OPTIONS,
  InitialAirlineName,
  InitialFilters,
  InitialPrice,
  InitialStops,
} from "../constants";
import {
  getMinMaxPrice,
  getMinPriceOnAirlines,
  getMinPriceOnStops,
  isSameObj,
  parsePrice,
} from "../helper";
import useBoundTImeSlider from "./useBoundTImeSlider";
import useFlightsToast from "./useFlightsToast";
import usePrevious from "./usePrevious";
import useScrollPopup from "./useScrollPopup";
import { HALT_DURATIONS } from "./../constants";

const useUpdateCallback = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const cbRef = useRef();

  useEffect(() => {
    cbRef.current && cbRef.current(state);
  }, [state]);

  const updateState = (arg1, cb) => {
    setState(arg1);
    cbRef.current = cb;
  };

  return [state, updateState];
};

const useFlights = (props) => {
  //query params
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const cabinQuery = urlParams.get("cabin") || CABIN_OPTIONS[0].value;
  const tripQuery = urlParams.get("trip");

  //context
  const context = useContext(SearchContext);
  const {
    searchReasult: searchResult,
    flightList: flightContext,
    SettingList: settingList,
    resetFlightList,
    fetchFlightList,
  } = context;

  //state
  const [flightList, setFlightList] = useState({
    data: [],
    iataAirlineCodes: [],
    id: "",
    oneWay: "",
  });
  const [airlineNames, setAirlineNames] = useState([]);
  const [airportNames, setAirportNames] = useState([]);
  const [user, setUser] = useState();
  const [buttonText, setButtonText] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    stops: InitialStops,
    price: InitialPrice,
    airlines: InitialAirlineName,
    hlats: [],
  });

  const [filterSelected, setFilterSelected] = useUpdateCallback({
    ...InitialFilters,
    stops: [],
    halts: [],
    price: { min: 0, max: 0 },
    cabin: cabinQuery || CABIN_OPTIONS[0].value,
  });

  //from props
  const { toFromData } = props;

  //api service
  const flightService = new FlightService();
  const getTimeInNumber = useCallback((timeString = " ") => {
    const [h = "", m = ""] = timeString.split(" ");
    return Number(h.replace("h", "")) + Number(Number(m.replace("m", "")) / 60);
  }, []);
  const getListOnPrice = (list = [], data) => {
    // list = JSON.parse(JSON.stringify(list));
    var slider = window.$("#price-slider").data("ionRangeSlider");
    let price = {
      min: slider.result.from,
      max: slider.result.to,
    };

    let fList = list.filter((el) => {
      if (price.min > 0 && price.max) {
        if (
          el.AdultFares.perAdultFare >= price.min &&
          el.AdultFares.perAdultFare <= price.max + 1
        ) {
          return el;
        }
        return false;
      } else {
        return el;
      }
    });

    if (!isSameObj(price, filterSelected.price))
      setFilterSelected((prev) => ({ ...prev, price: price }));

    return fList;
  };
  const margin = 25;
  const numberOfFlightsToPick = 3;
  const getBestFlights = (fList = []) => {
    // var layoverof10 = fList.filter(
    //   (f) =>
    //     f.outwardSegment.reduce(
    //       (x, y) =>
    //         x +
    //         (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
    //       0
    //     )
    // )
    // var finnAirAndUnitedAirlinesFlights = fList.filter(
    //   (f) =>
    //     f.iataAirlineCodes.includes("AY") || f.iataAirlineCodes.includes("LH")
    // );
    fList = _.sortBy(fList, [
      function (o) {
        return o.AdultFares.perAdultFare;
      },
    ]);
    var cheapest_fare =
      Math.round(fList[0]?.AdultFares.perAdultFare) +
      0.4 * fList[0]?.AdultFares.perAdultFare;
    //console.log(cheapest_fare);
    var isRoundTrip = fList[0]?.type === 0 ? true : false;
    if (isRoundTrip) {
      var directFlights = fList?.filter(
        (f) =>
          f.inwardStop == 0 &&
          f.outwardStop == 0 &&
          f.AdultFares.perAdultFare <= cheapest_fare
      );
      directFlights = _.sortBy(directFlights, [
        function (o) {
          return o.AdultFares.perAdultFare;
        },
      ]);
      directFlights.splice(numberOfFlightsToPick, directFlights.length);

      //console.log("directFlights", directFlights.length);
      var inboundIndirectflights = fList?.filter(
        (f) =>
          f.inwardStop == 1 &&
          f.outwardStop == 0 &&
          f.inwardSegment.reduce(
            (x, y) =>
              x +
              (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
            0
          ) < 4 &&
          f.AdultFares.perAdultFare <= cheapest_fare
      );
      inboundIndirectflights = _.sortBy(inboundIndirectflights, [
        // function (o) {
        //   return  (o.inwardSegment.reduce(
        //     (x, y) => x + (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
        //     0
        //   ));
        // },
        function (o) {
          return o.AdultFares.perAdultFare;
        },
      ]);
      inboundIndirectflights.splice(
        numberOfFlightsToPick,
        inboundIndirectflights.length
      );
      //console.log("inboundIndirectflights", inboundIndirectflights.length);
      var outboundIndirectflights = [];
      outboundIndirectflights = fList?.filter(
        (f) =>
          f.inwardStop == 0 &&
          f.outwardStop == 1 &&
          f.outwardSegment.reduce(
            (x, y) =>
              x +
              (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
            0
          ) < 4 &&
          f.AdultFares.perAdultFare <= cheapest_fare
      );
      outboundIndirectflights = _.sortBy(outboundIndirectflights, [
        // function (o) {
        //   return  o.outwardSegment.reduce(
        //     (x, y) => x + (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
        //     0
        //   );
        // }
        function (o) {
          return o.AdultFares.perAdultFare;
        },
      ]);
      outboundIndirectflights.splice(
        numberOfFlightsToPick,
        outboundIndirectflights.length
      );
      //console.log("outboundIndirectflights", outboundIndirectflights.length);
      var inboundoutboundIndirectflights = fList?.filter(
        (f) =>
          f.inwardStop == 1 &&
          f.outwardStop == 1 &&
          f.inwardSegment.reduce(
            (x, y) =>
              x +
              (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
            0
          ) < 4 &&
          f.outwardSegment.reduce(
            (x, y) =>
              x +
              (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
            0
          ) < 4 &&
          f.AdultFares.perAdultFare <= cheapest_fare
      );
      inboundoutboundIndirectflights = _.orderBy(
        inboundoutboundIndirectflights,
        [
          // function (o) {
          //   return o.outwardSegment.reduce(
          //     (x, y) =>
          //       x +
          //       (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
          //     0
          //   );
          // },
          // function (o) {
          //   return o.inwardSegment.reduce(
          //     (x, y) =>
          //       x +
          //       (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
          //     0
          //   );
          // },
          function (o) {
            return o.AdultFares.perAdultFare;
          },
        ],
        ["asc"]
      );
      inboundoutboundIndirectflights.splice(
        numberOfFlightsToPick,
        inboundoutboundIndirectflights.length
      );
      var bestFlights = directFlights
        .concat(inboundIndirectflights)
        .concat(outboundIndirectflights)
        .concat(inboundoutboundIndirectflights);

      if (directFlights.length === 0) {
        bestFlights = _.orderBy(
          bestFlights,
          [
            function (o) {
              return o.AdultFares.perAdultFare;
            },
          ],
          ["desc"]
        );
        var maxFare = bestFlights[0]?.AdultFares.perAdultFare;
        maxFare += Math.round(0.2 * maxFare);
        directFlights = fList?.filter(
          (f) =>
            f.inwardStop == 0 &&
            f.outwardStop == 0 &&
            f.AdultFares.perAdultFare <= maxFare
        );
        directFlights = _.sortBy(directFlights, [
          function (o) {
            return o.AdultFares.perAdultFare;
          },
        ]);
        directFlights.splice(numberOfFlightsToPick, directFlights.length);
        bestFlights = bestFlights.concat(directFlights);
      }
      if (bestFlights && bestFlights.length > 0) {
        bestFlights.sort(
          function (a, b) {
            if (
              (a.inwardStop === 1 && a.outwardStop === 0) ||
              (a.outwardStop === 1 && a.inwardStop === 0)
            ) {
              if (b.inwardStop === 0 && b.outwardStop === 0) {
                return a.AdultFares.perAdultFare + margin <
                  b.AdultFares.perAdultFare
                  ? -1
                  : a.AdultFares.perAdultFare + margin ===
                    b.AdultFares.perAdultFare
                  ? 0
                  : 1;
              } else if (b.inwardStop === 1 && b.outwardStop === 1) {
                return a.AdultFares.perAdultFare <
                  b.AdultFares.perAdultFare + margin
                  ? -1
                  : a.AdultFares.perAdultFare ===
                    b.AdultFares.perAdultFare + margin
                  ? 0
                  : 1;
              } else {
                return a.AdultFares.perAdultFare < b.AdultFares.perAdultFare
                  ? -1
                  : a.AdultFares.perAdultFare === b.AdultFares.perAdultFare
                  ? 0
                  : 1;
              }
            } else if (a.inwardStop === 1 && a.outwardStop === 1) {
              if (
                (b.inwardStop === 0 && b.outwardStop === 0) ||
                (b.inwardStop === 1 && b.outwardStop === 0) ||
                (b.outwardStop === 1 && b.inwardStop === 0)
              ) {
                return a.AdultFares.perAdultFare + margin <
                  b.AdultFares.perAdultFare
                  ? -1
                  : a.AdultFares.perAdultFare + margin ===
                    b.AdultFares.perAdultFare
                  ? 0
                  : 1;
              } else {
                return a.AdultFares.perAdultFare < b.AdultFares.perAdultFare
                  ? -1
                  : a.AdultFares.perAdultFare === b.AdultFares.perAdultFare
                  ? 0
                  : 1;
              }
            } else if (a.inwardStop === 0 && a.outwardStop === 0) {
              if (
                (b.inwardStop === 1 && b.outwardStop === 1) ||
                (b.inwardStop === 1 && b.outwardStop === 0) ||
                (b.outwardStop === 1 && b.inwardStop === 0)
              ) {
                return a.AdultFares.perAdultFare <
                  b.AdultFares.perAdultFare + margin
                  ? -1
                  : a.AdultFares.perAdultFare ===
                    b.AdultFares.perAdultFare + margin
                  ? 0
                  : 1;
              } else {
                return a.AdultFares.perAdultFare < b.AdultFares.perAdultFare
                  ? -1
                  : a.AdultFares.perAdultFare === b.AdultFares.perAdultFare
                  ? 0
                  : 1;
              }
            }
          }
          // ]
        );
      }
      if (
        bestFlights.length > 0 &&
        bestFlights[0].outwardStop === 0 &&
        bestFlights[0].inwardStop === 0
      ) {
        bestFlights = fList?.filter(
          (f) => f.inwardStop == 0 && f.outwardStop == 0
        );
        bestFlights = _.sortBy(bestFlights, [
          function (o) {
            return o.AdultFares.perAdultFare;
          },
        ]);
      }
      bestFlights.map(function (x) {
        var pos = fList.findIndex((y) => y.id === x.id);
        if (pos > -1) {
          fList.splice(pos, 1);
        }
      });
      fList = bestFlights.concat(fList);

      // }
      return fList;
    } //one way flights
    else {
      var directFlights = fList?.filter(
        (f) => f.outwardStop == 0 && f.AdultFares.perAdultFare <= cheapest_fare
      );
      directFlights = _.sortBy(directFlights, [
        function (o) {
          return o.AdultFares.perAdultFare;
        },
      ]);
      directFlights.splice(numberOfFlightsToPick, directFlights.length);
    }
    var outboundIndirectflights = [];
    outboundIndirectflights = fList?.filter(
      (f) =>
        f.outwardStop == 1 &&
        f.outwardSegment.reduce(
          (x, y) =>
            x + (y.layoverDuration != 0 && getTimeInNumber(y.layoverDuration)),
          0
        ) < 4 &&
        f.AdultFares.perAdultFare <= cheapest_fare
    );
    outboundIndirectflights = _.sortBy(outboundIndirectflights, [
      function (o) {
        return o.AdultFares.perAdultFare;
      },
    ]);
    outboundIndirectflights.splice(
      numberOfFlightsToPick,
      outboundIndirectflights.length
    );
    var bestFlights = directFlights.concat(outboundIndirectflights);

    if (directFlights.length === 0) {
      bestFlights = _.orderBy(
        bestFlights,
        [
          function (o) {
            return o.AdultFares.perAdultFare;
          },
        ],
        ["desc"]
      );
      var maxFare = bestFlights[0]?.AdultFares.perAdultFare;
      maxFare += Math.round(0.2 * maxFare);
      directFlights = fList?.filter(
        (f) => f.outwardStop == 0 && f.AdultFares.perAdultFare <= maxFare
      );
      directFlights = _.sortBy(directFlights, [
        function (o) {
          return o.AdultFares.perAdultFare;
        },
      ]);
      directFlights.splice(numberOfFlightsToPick, directFlights.length);
      bestFlights = bestFlights.concat(directFlights);
    }
    if (bestFlights && bestFlights.length > 0) {
      bestFlights.sort(function (a, b) {
        if (a.outwardStop === 1) {
          if (b.outwardStop === 0) {
            return a.AdultFares.perAdultFare + margin <
              b.AdultFares.perAdultFare
              ? -1
              : a.AdultFares.perAdultFare + margin === b.AdultFares.perAdultFare
              ? 0
              : 1;
          } else if (b.outwardStop === 1) {
            return a.AdultFares.perAdultFare < b.AdultFares.perAdultFare
              ? -1
              : a.AdultFares.perAdultFare === b.AdultFares.perAdultFare
              ? 0
              : 1;
          }
        } else if (a.outwardStop === 0) {
          if (b.outwardStop === 0) {
            return a.AdultFares.perAdultFare < b.AdultFares.perAdultFare
              ? -1
              : a.AdultFares.perAdultFare === b.AdultFares.perAdultFare
              ? 0
              : 1;
          } else if (b.outwardStop === 1) {
            return a.AdultFares.perAdultFare <
              b.AdultFares.perAdultFare + margin
              ? -1
              : a.AdultFares.perAdultFare === b.AdultFares.perAdultFare + margin
              ? 0
              : 1;
          }
        }
      });
    }
    if (bestFlights.length > 0 && bestFlights[0].outwardStop === 0) {
      bestFlights = fList?.filter((f) => f.outwardStop == 0);
      bestFlights = _.sortBy(bestFlights, [
        function (o) {
          return o.AdultFares.perAdultFare;
        },
      ]);
    }
    bestFlights.map(function (x) {
      var pos = fList.findIndex((y) => y.id === x.id);
      if (pos > -1) {
        fList.splice(pos, 1);
      }
    });
    fList = bestFlights.concat(fList);
    return fList;
  };
  const getListOnAirlines = (list = [], airlines = []) => {
    // if there is no airline selected then we have consider all the airlines
    let selectedAirlines =
      airlines?.length === 0 ? airlineNames?.map((x) => x.iata) : airlines;
    if (airlines?.length === airlineNames?.length || airlines?.length === 0) {
      // const stopsOptions = getMinPriceOnStops(list, filterSelected.stops, filterOptions.stops);
      // setFilterOptions(prev => ({
      //   ...prev,
      //   stops: stopsOptions
      // }))
      return list;
    }
    // if (selectedAirlines?.length !== airlineNames?.length) {
    let fList = list.filter((el) => {
      for (let el2 of el.iataAirlineCodes) {
        if (selectedAirlines.includes(el2)) {
          return el;
        }
      }
    });

    // const stopsOptions = getMinPriceOnStops(fList, filterSelected.stops, filterOptions.stops);
    // setFilterOptions(prev => ({
    //   ...prev,
    //   stops: stopsOptions
    // }))
    return fList;
  };

  const getListOnHalts = (list = [], halts = []) => {
    if (halts.length === 0) return list;
    let fList = list.filter((item) => {
      // to calculate the total timings consider the layoverDuration from each segments except
      // very first segments of inwardSegment and outwardSegment because first flight will not have the layover (Of course)
      const [a, ...inwardSegment] = item.inwardSegment;
      const [b, ...outwardSegment] = item.outwardSegment;

      // checking for direct flights which will always have zero overlay, should not be considered under any halts filter
      if (
        halts.length !== 0 &&
        inwardSegment.length === 0 &&
        outwardSegment.length === 0
      )
        return false;

      // inward and outward layover's sum
      let inwardLayover = inwardSegment.reduce(
        (x, y) => x + getTimeInNumber(y.layoverDuration),
        0
      );
      let outwardLayover = outwardSegment.reduce(
        (x, y) => x + getTimeInNumber(y.layoverDuration),
        0
      );

      // checking for each layover filter option and filltering out the flights
      let show = false;
      const checkCondition = (range) => {
        let considerOutwardLayover = false;
        let considerInwardLayover = false;
        if (range[0] <= outwardLayover && range[1] >= outwardLayover) {
          considerOutwardLayover = true;
        }
        if (range[0] <= inwardLayover && range[1] >= inwardLayover) {
          considerInwardLayover = true;
        }
        return considerInwardLayover || considerOutwardLayover;
      };

      if (
        halts.includes("short") &&
        checkCondition(HALT_DURATIONS.short.range)
      ) {
        show = true;
      }
      if (
        halts.includes("relaxed") &&
        checkCondition(HALT_DURATIONS.relaxed.range)
      ) {
        show = true;
      }
      if (halts.includes("long") && checkCondition(HALT_DURATIONS.long.range)) {
        show = true;
      }
      if (
        halts.includes("longer") &&
        HALT_DURATIONS.longer.range[0] <= outwardLayover &&
        HALT_DURATIONS.longer.range[0] <= inwardLayover
      ) {
        show = true;
      }
      return show;
    });

    return fList;
  };

  const getListOnTimings = (list = [], times = {}) => {
    const { inbound = {}, outbound = {} } = times;
    if (isSameObj(InitialFilters.time, times)) {
      return list;
    }

    var outBSlider = window.$("#OUTBOUND-slider").data("ionRangeSlider");
    outBSlider &&
      outBSlider.update({
        from: outbound.min,
        to: outbound.max,
      });

    var inBSlider = window.$("#INBOUND-slider").data("ionRangeSlider");
    inBSlider &&
      inBSlider.update({
        from: inbound.min,
        to: inbound.max,
      });

    const obj = {};

    let fList = _.filter(list, (el, i) => {
      const outBoundHour =
        new Date(el.outwardSegment[0]?.departure.dateTime).getHours() +
        new Date(el.outwardSegment[0]?.departure.dateTime).getMinutes() / 60;
      const inBoundHour =
        el.inwardSegment?.length &&
        new Date(el.inwardSegment[0].departure.dateTime).getHours() +
          new Date(el.inwardSegment[0]?.departure.dateTime).getMinutes() / 60;
      let outBoundResult =
        outBoundHour >= outbound.min && outBoundHour <= outbound.max;
      let inBoundResult =
        inBoundHour >= inbound.min && inBoundHour <= inbound.max;
      obj[i] = { outBoundHour, inBoundHour, outBoundResult, inBoundResult };
      return el.inwardSegment?.length
        ? outBoundResult && inBoundResult
        : outBoundResult;
    });
    return fList;
  };

  const { isScrollForPopup, setIsScrollForPopup } = useScrollPopup({
    loading: flightContext.searchLoader,
  });

  const getAirportNames = async (data) => {
    let result = await flightService.ListgetAirportCode(data);
    setAirportNames(result);
  };

  const getTotalTravellers = () => {
    return (
      parseInt(searchResult.adult) +
      parseInt(searchResult.child) +
      parseInt(searchResult.infant)
    );
  };

  const getCheapestPrice = () => {
    if (flightList.data.length) {
      return Math.min(
        ...flightList.data.map((item) =>
          parseFloat(item.AdultFares.perAdultFare).toFixed(2)
        )
      );
    } else {
      return 0;
    }
  };

  const handleSearchClick = () => {
    setIsScrollForPopup(false);
    setFilterSelected(() => ({
      ...InitialFilters,
      cabin: cabinQuery,
      price: { min: filterOptions.price.min, max: filterOptions.price.max },
      stops: [],
      halts: [],
      time: { inbound: { max: 24, min: 0 }, outbound: { max: 24, min: 0 } },
    }));
  };

  const updatePriceSlider = (data = {}) => {
    let { min = 0, max = 100 } = data;
    var slider = window.$("#price-slider").data("ionRangeSlider");
    let newData = {
      min: parsePrice(min),
      max: parsePrice(max),
      from: parsePrice(min),
      to: parsePrice(max),
    };
    if (slider) {
      slider.update(newData);
    }
    setFilterSelected((prev) => ({
      ...prev,
      price: newData,
    }));
    // onFiltersChange({
    //   ...filterSelected,
    //   price: { min, max },
    // })
  };

  const setupPriceSlider = ({ min = 0, max = 100 }) => {
    // setting up prices
    if (Number.isNaN(min) && Number.isNaN(max)) return false;
    window.$("#price-slider").ionRangeSlider({
      type: "double",
      prefix: "£",
      prettify_enabled: false,
      min: parsePrice(min),
      max: parsePrice(max),
      onFinish: (e) => {
        const { from, to } = e;
        setFilterSelected((prev) => {
          let updatedData = {
            ...prev,
            price: { min: from, max: to },
          };
          return updatedData;
        });
      },
    });
  };

  const onNewFlightsArrives = async () => {
    const { IataCodes, ...priceRange } = getMinMaxPrice(flightContext.data);
    const stopsOptions = getMinPriceOnStops(flightContext.data, []);
    const { airlines, airlineNames: newAirlineNames } =
      await getMinPriceOnAirlines(flightContext, flightService);
    setFilterOptions((prev) => ({
      ...prev,
      price: priceRange,
      stops: stopsOptions,
      airlines: airlines,
    }));

    setFilterSelected(
      {
        stops: [],
        time: { inbound: { max: 24, min: 0 }, outbound: { max: 24, min: 0 } },
        airlines: [],
        halts: [],
        cabin: cabinQuery,
        price: priceRange,
      },
      (state) => {
        if (!isSameObj(state, InitialFilters)) onFiltersChange(state);
      }
    );
    if (IataCodes?.length) {
      getAirportNames(IataCodes);
    }
    setAirlineNames(newAirlineNames);
    setFlightList({ ...flightContext });
    updatePriceSlider(priceRange);
  };

  const getListOnStops = (list = [], stops = []) => {
    var slider = window.$("#price-slider").data("ionRangeSlider");
    const { result } = slider || {};
    // list = JSON.parse(JSON.stringify(list));

    // if there is no cabin selected then we have consider all the stops
    let selectedStops = stops?.length === 0 ? InitialFilters.stops : stops;

    if (isSameObj(stops, InitialStops)) {
      return list;
    }

    const tStops = {
      nonstop: {
        id: 0,
        apply: Boolean(selectedStops.find((x) => x === "nonstop")),
      },
      "1stop": {
        id: 1,
        apply: Boolean(selectedStops.find((x) => x === "1stop")),
      },
      "2stop": {
        id: 2,
        apply: Boolean(selectedStops.find((x) => x === "2stop")),
      },
    };

    const fStops = Object.values(tStops)
      .filter(({ apply }) => apply)
      .map((x) => x.id);

    let fList = _.filter(list, (el) => {
      // QWERTY: "OR" CONDITION FOR STOPS
      // return fStops.includes(el.outwardStop) || fStops.includes(el.inwardStop);
      // QWERTY: "AND" CONDITION FOR STOPS
      if (tripQuery === "1")
        return (
          fStops.includes(el.outwardStop) || fStops.includes(el.inwardStop)
        );
      else
        return (
          fStops.includes(el.outwardStop) && fStops.includes(el.inwardStop)
        );
    });

    // calculating min price based on selected stops.
    const tempStops = filterOptions.stops
      .filter((x) => selectedStops.includes(x.value) && x.minprice)
      .map((x) => x.minprice);

    const minPrice = Math.min(...tempStops);
    let min = Number.isNaN(parseInt(minPrice)) ? 0 : parseInt(minPrice);

    // slider.update({
    //   min,
    //   from: min,
    // });

    const priceRange = {
      max: slider.result?.max,
      to: slider.result?.to,
      min: Number.isNaN(parseInt(minPrice)) ? 0 : parseInt(minPrice),
      form: result.from > minPrice ? parseInt(minPrice) : result.from,
    };
    return { fList, priceRange };
  };

  //const oldFilters = usePrevious(filterSelected);
  const onFiltersChange = (filters, set = true) => {
    if (isSameObj(filters, InitialFilters)) {
      return setFlightList({ ...flightContext });
    }

    let fList = flightContext.data;
    let { fList: lfList, priceRange } = getListOnStops(fList, filters.stops);
    // filters.price = priceRange
    set && setFilterSelected(filters);
    fList = getListOnPrice(lfList, priceRange);
    // if (!isSameObj(oldFilters?.airlines || [], filters.airlines)) {
    fList = getListOnAirlines(fList, filters.airlines);
    // }
    fList = getListOnTimings(fList, filters.time);
    fList = getListOnHalts(fList, filters.halts);

    let offerData = fList.filter(({ isOffer }) => isOffer);
    fList = fList.filter(({ isOffer }) => !isOffer);
    offerData.forEach((d, i) => {
      fList.splice(i * 3, 0, d);
    });

    fList = getBestFlights(fList);
    setFlightList((prev) => ({ ...prev, data: fList }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // managing CallPopup by detecting scroll at 6th flight data
  useEffect(() => {
    setUser(window.user);
    setupPriceSlider({});

    return () => {
      // resetFlightList(); // Not useful ?
      window.removeEventListener("scroll", () => false);
    };
  }, []);

  const oldList = usePrevious(flightContext.data);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (JSON.stringify(oldList) !== JSON.stringify(flightContext.data)) {
      onNewFlightsArrives();
    }
  }, [flightContext]);

  useEffect(() => {
    onFiltersChange(filterSelected, false);
  }, [filterSelected.price]);

  // handling toast from here
  useFlightsToast({
    flights: flightContext.data,
    setting: settingList,
    toFromData,
  });

  useBoundTImeSlider({
    time: filterSelected.time,
    setFilterSelected,
    onFiltersChange: () => onFiltersChange(filterSelected),
  });

  return {
    ...props,
    flightList,
    airlineNames,
    filterOptions,
    filterSelected,
    searchResult,
    flightContext,
    settingList,
    airportNames,
    buttonText,
    setButtonText,
    isScrollForPopup,
    getAirportNames,
    getTotalTravellers,
    getCheapestPrice,
    handleSearchClick,
    onFiltersChange,
    fetchFlightList,
  };
};

export default useFlights;
