import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ArrowTooltips from "../../Shared/Tooltip";

const PhoneDeal = (props) => {
  const { el, i } = props;
  const { airportName = [] } = props;
  return (
    <>
      <div className="popup-card phone-deal-card" key={el.id}>
        <div className="ribbon ribbon-top-right">
          <span>Deal Now</span>
        </div>
        <div className="popup-card-header phone-deal-card-header">
          This is Special Phone Deal Only
        </div>
        <a
          className="phone-deal-card-body"
          href={`#searchResultsItem-${i + 1}`}
          role="button"
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls={`searchResultsItem-${i + 1}`}
        >
          <div className="col-md-10 width100">
            <div className="theme-search-results-item-flight-sections">
              <div className="theme-search-results-item-flight-section flight-section">
                <div className="row row-no-gutter row-eq-height">
                  <div className="col-xs-2 col-md-2">
                    <div className="theme-search-results-item-flight-section-airline-logo-wrap">
                      <img
                        className="theme-search-results-item-flight-section-airline-logo"
                        src={`/assets/img/logos/${el.outwardSegment[0].carrierCode}.gif`}
                        alt="Image Alter"
                        title="Image Title"
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 col-md-10">
                    {/*  <div className="theme-search-results-item-flight-section-item"> */}
                    <div className="row" style={{ margin: "0" }}>
                      <div
                        className="col-sm-3 col-xs-4"
                        style={{ paddingLeft: "0" }}
                      >
                        <div className="theme-search-results-item-flight-section-meta flight-right-meta-text">
                          <p className="theme-search-results-item-flight-section-meta-time">
                            {moment(
                              el.outwardSegment[0].departure.dateTime
                            ).format("HH:mm")}
                          </p>
                          <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                            {el.outwardSegment.map((el1, i1) => {
                              if (i1 === 0)
                                return moment(el1.departure.dateTime).format(
                                  "ll"
                                );
                            })}
                          </p>
                          <p className="theme-search-result-airport-name">
                            Airport name here
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-sm-5 col-xs-4"
                        style={{ padding: "0" }}
                      >
                        <div className="theme-search-results-item-flight-section-path flight-search-path-section">
                          <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                            <p className="fly-time-text-content">
                              <span>
                                {el.outwardStop == 0
                                  ? "DIRECT"
                                  : el.outwardStop +
                                    `${
                                      el.outwardStop == 1 ? " Stop" : " Stops"
                                    }`}{" "}
                              </span>
                              <span className="fly-time-icon-span">
                                | <img src="/assets/ficon/clock.png" />
                              </span>
                              {el.totalOutboundDuration}{" "}
                            </p>
                          </div>

                          <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                          <div className="theme-search-results-item-flight-section-path-line-start">
                            <img
                              src="/assets/ficon/airplane-take-off.png"
                              className="airplane-take-off"
                              alt="airplane-take-off"
                            />

                            <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                            <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                              {!(airportName && airportName.length)
                                ? el.outwardSegment.map((el, i) => {
                                    if (i === 0) return el.departure.iataCode;
                                  })
                                : el.outwardSegment.map((el1, i) => {
                                    if (i === 0) {
                                      return airportName.map((el2) => {
                                        if (
                                          el2.iata === el1.departure.iataCode
                                        ) {
                                          return (
                                            <ArrowTooltips
                                              text={el2.airport_name}
                                            >
                                              <span>
                                                {el1.departure.iataCode}
                                              </span>
                                            </ArrowTooltips>
                                          );
                                        }
                                      });
                                    }
                                  })}
                            </div>
                          </div>
                          {el.outwardStop === 1 && (
                            <div className="theme-search-results-item-flight-section-path-line-end">
                              <img
                                src="/assets/ficon/airplane-landing.png"
                                className="airplane-take-off"
                                alt="airplane-take-off"
                              />
                              <div className="theme-search-results-item-flight-section-path-line-dot" />
                              <div
                                className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                style={{ color: "#7A7E82" }}
                              >
                                {!(airportName && airportName.length)
                                  ? el.outwardSegment.map((el, i) => {
                                      if (i === 0) return el.arrival.iataCode;
                                    })
                                  : el.outwardSegment.map((el1, i) => {
                                      if (i === 0) {
                                        return airportName.map((el2) => {
                                          if (
                                            el2.iata === el1.arrival.iataCode
                                          ) {
                                            return (
                                              <ArrowTooltips
                                                text={el2.airport_name}
                                              >
                                                <span>
                                                  {el1.arrival.iataCode}
                                                </span>
                                              </ArrowTooltips>
                                            );
                                          }
                                        });
                                      }
                                    })}
                              </div>
                            </div>
                          )}
                          {el.outwardStop === 2 && (
                            <>
                              <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                <div
                                  style={{ color: "#7A7E82" }}
                                  className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                >
                                  {!(airportName && airportName.length)
                                    ? el.outwardSegment.map((el, i) => {
                                        if (i === 0) return el.arrival.iataCode;
                                      })
                                    : el.outwardSegment.map((el1, i) => {
                                        if (i === 0) {
                                          return airportName.map((el2) => {
                                            if (
                                              el2.iata === el1.arrival.iataCode
                                            ) {
                                              return (
                                                <ArrowTooltips
                                                  text={el2.airport_name}
                                                >
                                                  <span>
                                                    {el1.arrival.iataCode}
                                                  </span>
                                                </ArrowTooltips>
                                              );
                                            }
                                          });
                                        }
                                      })}
                                </div>
                              </div>
                              <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon" />
                                <div className="theme-search-results-item-flight-section-path-line-dot" />
                                <div
                                  style={{ color: "#7A7E82" }}
                                  className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                >
                                  {!(airportName && airportName.length)
                                    ? el.outwardSegment.map((el, i) => {
                                        if (i === 1) return el.arrival.iataCode;
                                      })
                                    : el.outwardSegment.map((el1, i) => {
                                        if (i === 1) {
                                          return airportName.map((el2) => {
                                            if (
                                              el2.iata === el1.arrival.iataCode
                                            ) {
                                              return (
                                                <ArrowTooltips
                                                  text={el2.airport_name}
                                                >
                                                  <span>
                                                    {el1.arrival.iataCode}
                                                  </span>
                                                </ArrowTooltips>
                                              );
                                            }
                                          });
                                        }
                                      })}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="theme-search-results-item-flight-section-path-line-end">
                            <img
                              src="/assets/ficon/airplane-landing.png"
                              className="airplane-take-off"
                              alt="airplane-take-off"
                            />
                            <div className="theme-search-results-item-flight-section-path-line-dot" />
                            <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                              {!(airportName && airportName.length)
                                ? el.outwardSegment.map((el1, i) => {
                                    if (i === el.outwardSegment.length - 1)
                                      return el1.arrival.iataCode;
                                  })
                                : el.outwardSegment.map((el1, i) => {
                                    if (i === el.outwardSegment.length - 1) {
                                      return airportName.map((el2) => {
                                        if (el2.iata === el1.arrival.iataCode) {
                                          return (
                                            <ArrowTooltips
                                              text={el2.airport_name}
                                            >
                                              <span>
                                                {el1.arrival.iataCode}
                                              </span>
                                            </ArrowTooltips>
                                          );
                                        }
                                      });
                                    }
                                  })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-sm-3 col-xs-4"
                        style={{ paddingRight: "0" }}
                      >
                        <div className="theme-search-results-item-flight-section-meta flight-left-meta-text">
                          {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                          <p className="theme-search-results-item-flight-section-meta-time">
                            {el.outwardSegment.map((el1, i) => {
                              if (i == el.outwardSegment.length - 1) {
                                return moment(el1.arrival.dateTime).format(
                                  "HH:mm"
                                );
                              }
                            })}
                          </p>
                          <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                            {el.outwardSegment.map((el1, i1) => {
                              if (i1 === el.outwardSegment.length - 1)
                                return moment(el1.departure.dateTime).format(
                                  "ll"
                                );
                            })}
                          </p>
                          <p className="theme-search-result-airport-name">
                            Airport name here
                          </p>
                        </div>
                      </div>
                      <div className="hidden-xs col-sm-1 border-left">
                        <ArrowTooltips text="Hand Luggage Only">
                          <img
                            className="_mb-10 image-icons"
                            src="/assets/ficon/luggage-1.png"
                            alt="luggage"
                          />
                        </ArrowTooltips>
                        <ArrowTooltips text="Atol Protected">
                          <img
                            className="_mb-10 image-icons"
                            src="/assets/ficon/atol_protection.png"
                            id="flight-seat"
                            alt="atol_protection"
                          />
                        </ArrowTooltips>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              {!props.oneWay ? (
                <div className="theme-search-results-item-flight-section flight-section">
                  <div className="row row-no-gutter row-eq-height">
                    <div className="col-xs-2 col-md-2">
                      <div className="theme-search-results-item-flight-section-airline-logo-wrap">
                        <img
                          className="theme-search-results-item-flight-section-airline-logo"
                          src={`/assets/img/logos/${el.inwardSegment[0].carrierCode}.gif`}
                          alt="Image Alter"
                          title="Image Title"
                        />
                      </div>
                    </div>
                    <div className="col-xs-10 col-md-10">
                      <div className="theme-search-results-item-flight-section-item">
                        <div className="row">
                          <div
                            className="col-sm-3 col-xs-4"
                            style={{ paddingLeft: "0" }}
                          >
                            <div className="theme-search-results-item-flight-section-meta flight-right-meta-text">
                              <p className="theme-search-results-item-flight-section-meta-time">
                                {el.inwardSegment.map((el1, i) => {
                                  if (i == 0) {
                                    return moment(
                                      el1.departure.dateTime
                                    ).format("HH:mm");
                                  }
                                })}
                              </p>
                              <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                                {el.inwardSegment.map((el1, i) => {
                                  if (i == 0) {
                                    return moment(
                                      el1.departure.dateTime
                                    ).format("ll");
                                  }
                                })}
                              </p>
                              <p className="theme-search-result-airport-name">
                                Airport name here
                              </p>
                            </div>
                          </div>
                          <div
                            className="col-sm-5 col-xs-4"
                            style={{ padding: "0" }}
                          >
                            <div className="theme-search-results-item-flight-section-path flight-search-path-section">
                              <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                                <p className="fly-time-text-content">
                                  <span>
                                    {el.inwardStop == 0
                                      ? "DIRECT"
                                      : el.inwardStop +
                                        `${
                                          el.inwardStop == 1
                                            ? " Stop"
                                            : " Stops"
                                        }`}{" "}
                                  </span>
                                  <span className="fly-time-icon-span">
                                    | <img src="/assets/ficon/clock.png" />
                                  </span>
                                  {el.totalInboundDuration}
                                </p>
                              </div>

                              <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                              <div className="theme-search-results-item-flight-section-path-line-start">
                                <img
                                  src="/assets/ficon/airplane-take-off.png"
                                  className="airplane-take-off"
                                  alt="airplane-take-off"
                                />

                                <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                  {!(airportName && airportName.length)
                                    ? el.inwardSegment.map((el, i) => {
                                        if (i === 0)
                                          return el.departure.iataCode;
                                      })
                                    : el.inwardSegment.map((el1, i) => {
                                        if (i === 0) {
                                          return airportName.map((el2) => {
                                            if (
                                              el2.iata ===
                                              el1.departure.iataCode
                                            ) {
                                              return (
                                                <ArrowTooltips
                                                  text={el2.airport_name}
                                                >
                                                  <span>
                                                    {el1.departure.iataCode}
                                                  </span>
                                                </ArrowTooltips>
                                              );
                                            }
                                          });
                                        }
                                      })}
                                </div>
                              </div>
                              {el.inwardStop === 1 && (
                                <div className="theme-search-results-item-flight-section-path-line-end">
                                  <img
                                    src="/assets/ficon/airplane-landing.png"
                                    className="airplane-take-off"
                                    alt="airplane-take-off"
                                  />
                                  <div className="theme-search-results-item-flight-section-path-line-dot" />
                                  <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                    {!(airportName && airportName.length)
                                      ? el.inwardSegment.map((el, i) => {
                                          if (i === 0)
                                            return el.arrival.iataCode;
                                        })
                                      : el.inwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return airportName.map((el2) => {
                                              if (
                                                el2.iata ===
                                                el1.arrival.iataCode
                                              ) {
                                                return (
                                                  <ArrowTooltips
                                                    text={el2.airport_name}
                                                  >
                                                    <span>
                                                      {el1.arrival.iataCode}
                                                    </span>
                                                  </ArrowTooltips>
                                                );
                                              }
                                            });
                                          }
                                        })}
                                  </div>
                                </div>
                              )}
                              {el.inwardStop === 2 && (
                                <>
                                  <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                    <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon" />
                                    <div className="theme-search-results-item-flight-section-path-line-dot" />
                                    <div
                                      style={{ color: "#7A7E82" }}
                                      className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                    >
                                      {!(airportName && airportName.length)
                                        ? el.inwardSegment.map((el, i) => {
                                            if (i === 0)
                                              return el.arrival.iataCode;
                                          })
                                        : el.inwardSegment.map((el1, i) => {
                                            if (i === 0) {
                                              return airportName.map((el2) => {
                                                if (
                                                  el2.iata ===
                                                  el1.arrival.iataCode
                                                ) {
                                                  return (
                                                    <ArrowTooltips
                                                      text={el2.airport_name}
                                                    >
                                                      <span>
                                                        {el1.arrival.iataCode}
                                                      </span>
                                                    </ArrowTooltips>
                                                  );
                                                }
                                              });
                                            }
                                          })}
                                    </div>
                                  </div>
                                  <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                    <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon" />
                                    <div className="theme-search-results-item-flight-section-path-line-dot" />
                                    <div
                                      style={{ color: "#7A7E82" }}
                                      className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                    >
                                      {!(airportName && airportName.length)
                                        ? el.inwardSegment.map((el, i) => {
                                            if (i === 1)
                                              return el.arrival.iataCode;
                                          })
                                        : el.inwardSegment.map((el1, i) => {
                                            if (i === 1) {
                                              return airportName.map((el2) => {
                                                if (
                                                  el2.iata ===
                                                  el1.arrival.iataCode
                                                ) {
                                                  return (
                                                    <ArrowTooltips
                                                      text={el2.airport_name}
                                                    >
                                                      <span>
                                                        {el1.arrival.iataCode}
                                                      </span>
                                                    </ArrowTooltips>
                                                  );
                                                }
                                              });
                                            }
                                          })}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="theme-search-results-item-flight-section-path-line-end">
                                <img
                                  src="/assets/ficon/airplane-landing.png"
                                  className="airplane-take-off"
                                  alt="airplane-take-off"
                                />
                                <div className="theme-search-results-item-flight-section-path-line-dot" />
                                <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                  {!(airportName && airportName.length)
                                    ? el.inwardSegment.map((el1, i) => {
                                        if (i === el.inwardSegment.length - 1)
                                          return el1.arrival.iataCode;
                                      })
                                    : el.inwardSegment.map((el1, i) => {
                                        if (i === el.inwardSegment.length - 1) {
                                          return airportName.map((el2) => {
                                            if (
                                              el2.iata === el1.arrival.iataCode
                                            ) {
                                              return (
                                                <ArrowTooltips
                                                  text={el2.airport_name}
                                                >
                                                  <span>
                                                    {el1.arrival.iataCode}
                                                  </span>
                                                </ArrowTooltips>
                                              );
                                            }
                                          });
                                        }
                                      })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-sm-3 col-xs-4"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="theme-search-results-item-flight-section-meta flight-left-meta-text">
                              {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                              <p className="theme-search-results-item-flight-section-meta-time">
                                {el.inwardSegment.map((el1, i) => {
                                  if (i == el.inwardSegment.length - 1) {
                                    return moment(el1.arrival.dateTime).format(
                                      "HH:mm"
                                    );
                                  }
                                })}
                              </p>
                              <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                                {el.inwardSegment.map((el1, i1) => {
                                  if (i1 === el.inwardSegment.length - 1)
                                    return moment(el1.arrival.dateTime).format(
                                      "ll"
                                    );
                                })}
                              </p>
                              <p className="theme-search-result-airport-name">
                                Airport name here
                              </p>
                            </div>
                          </div>
                          <div className="hidden-xs col-sm-1 border-left">
                            <ArrowTooltips text="Hand Luggage Only">
                              <img
                                className="_mb-10 image-icons"
                                src="/assets/ficon/luggage-1.png"
                                alt="airplane-take-off"
                              />
                            </ArrowTooltips>
                            <ArrowTooltips text="Atol Protected">
                              <img
                                className="_mb-10 image-icons"
                                src="/assets/ficon/atol_protection.png"
                                id="flight-seat"
                                alt="flight-seat"
                              />
                            </ArrowTooltips>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-2 width100 border-left">
            <div className="theme-search-results-item-book flight-book">
              <p className="book-price">Total Price</p>
              <div className="theme-search-results-item-price">
                <p className="theme-search-results-item-price-tag">
                  £{el.totalFare.amount}
                </p>
                {/* <p className="theme-search-results-item-price-sign">Including All Taxes</p> */}
              </div>
              {props.flightLists.data.length > 1 ||
              window.location.pathname == "/search" ? (
                <>
                  <Link
                    className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                    to="flightdetails"
                  >
                    Call Now
                  </Link>
                </>
              ) : null}
            </div>
          </div>
        </a>
      </div>
    </>
  );
};
export default PhoneDeal;
