import React, { useEffect } from "react";
import { leftContentBar } from "../../Helper/helper";
import { QuickPayment } from "../../Service/UtilsService"
import {loadStripe} from '@stripe/stripe-js';
import {useState} from "react";
import {notification} from "../../Shared/NotificationModal";
import SimpleReactValidator from 'simple-react-validator';

class PayOnline extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        formData: {payment_type:"Nb", card_type: "Pdebit", country: "GB"},
        tnc: ""
    };
    this.validator = new SimpleReactValidator();
}

  // useEffect({

  // }, [])

  handleSubmit = async (e) => {

    if (this.validator.allValid()) {
      let payload = {
        ...this.state.formData,
        address2: this.state.formData?.address2 || "address line 2"
      }
      let response = await QuickPayment(payload);

      if (response) {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

          if(response.id && response.paymentId) {
              const stripe = await stripePromise;

              // When the customer clicks on the button, redirect them to Checkout.
              const result =  await stripe.redirectToCheckout({
                  sessionId: response.id,
              });

              if (result.error) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                  notification('error', result.error.message)
                }
          }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      const errorMessages = this.validator.errorMessages;
      for (const errorMessage in errorMessages) {
        const inputIds = {
          "Name On Card": "name_on_card",
          "Email": "email",
          "Mobile Number": "mob_no",
          "Address Line 1": "address1",
          "City": "city",
          "Postal Code": "post_code",
          "Country": "country",
          "Booking Reference No": "booking_ref_no",
          "Amount": "amount",
          "Payment Type": "payment_type",
          "Card Type": "card_type",
          "Terms and Condition": "terms-condition"
        };
        if (errorMessages[errorMessage]) {
          const inputId = inputIds[errorMessage];
          const inputElement = document.getElementById(inputId);
          inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setTimeout(() => {
            inputElement.focus();
          }, 400);
          break;
        }
      }
    }


  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({formData:{
      ...this.state.formData,
      [name]: value
    }});
  }
  handleChangeNumber = (event) => {
    this.setState({
      formData:{
        ...this.state.formData,
        "mob_no": event.target.value.replace(/[^0-9]/g, '')
    }});
  }
  handleTncChange = () => {
    this.setState({tnc: true});
  }
  render(){

    const { formData } = this.state
    return (
      <>
        <div>
          <div className="theme-hero-area front hero-area-bg _pv-mob-50">
            {/*         <div class="theme-hero-area-bg-wrap">
            <div class="theme-hero-area-bg" style={{ backgroundImage: `url(${Banner.bannerimage})` }}></div>
            <div class="theme-hero-area-mask theme-hero-area-mask-half"></div>
            <div class="theme-hero-area-inner-shadow"></div>
          </div> */}
            {/*         <div class="theme-hero-area-body">
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1 theme-page-header-abs">
                  <div class="theme-page-header theme-page-header-lg">
                    <h1 class="theme-page-header-title">{page.title} </h1>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="theme-hero-area-body">
              <div className="container">
                <div class="theme-hero-area-conatct-bg-wrap">
                  <div className="contact-us-title">
                    <div className="title-head text-primary">Pay Online</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="theme-page-section _pb-30 theme-page-section-gray theme-page-section-xl">
            <div className="container">
              <div className="row _mh-30 _mh-mob-0">
                {leftContentBar}
                <div className="col-md-9 _m-a">
                  <div className="card quick-pay-detail-card">
                    <div className="py-3 bg-white">
                      <h2 className="pl-20">Pay Online</h2>
                    </div>
                    <div className="card-body">
                      <p>
                        You will be redirected to payment gateway website to make
                        secure payment. We don't store/publish your card data
                      </p>
                      <p>
                        Please read our{" "}
                        <strong>
                          <a href="/pg/terms_conditions">Terms & Conditions</a>
                        </strong>{" "}
                        for refund policy and other details
                      </p>
                      <form className="_w-100pct _multi-line-flex">
                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-100pct">
                          <label for="email" class="label-text">
                            Card Holder Name<span class="red-mask">*</span>
                          </label>
                          <input
                            type="text"
                            id="name_on_card"
                            name="name_on_card"
                            class="form-control"
                            placeholder="Name On Card"
                            value={formData.name_on_card}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            "Name On Card",
                            formData.name_on_card,
                            'required|alpha_space',
                            {
                              messages: {
                                required: 'Card Holder Name is required',
                                alpha_space: 'Card Holder Name with only letters and spaces'
                              }
                            },
                            { className: 'text-danger' },
                            )}
                        </div>
                        <div>
                          <h2 className="text-primary">Contact Information</h2>
                          <p>
                            Please provide contact details aligned with the
                            payment card which will be used for payment, these
                            details will be populated on the Merchant payment
                            gateway website.
                          </p>
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="email" class="label-text">
                            Email<span class="red-mask">*</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            class="form-control"
                            placeholder="Email address"
                            value={formData.email}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            "Email",
                            formData.email,
                            'required|email',
                            { messages: { required: "Email is required", email: "Please enter a valid Email" } },
                            { className: 'text-danger' }
                            )
                          }
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="number" class="label-text">
                            Mobile Number<span class="red-mask">*</span>
                          </label>
                          <input
                            type="text"
                            id="mob_no"
                            name="mob_no"
                            class="form-control"
                            placeholder="Mobile Number"
                            value={formData.mob_no}
                            onChange={this.handleChangeNumber}
                            minLength={10}
                            maxLength={20}
                          />
                            {this.validator.message(
                              'Mobile Number',
                              formData.mob_no,
                              'required|max:20|min:10',
                              {
                                messages: {
                                  required: 'Mobile Number is required',
                                  max: 'Mobile Number must be a valid 20 digits',
                                  min: 'Mobile Number must be a valid 10 digits'
                                }
                              },
                              { className: 'text-danger' }
                            )}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="address1" class="label-text">
                            Address Line 1<span class="red-mask">*</span>
                          </label>
                          <input
                            type="text"
                            id="address1"
                            name="address1"
                            class="form-control"
                            placeholder="Address line 1"
                            value={formData.address1}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            "Address Line 1",
                            formData.address1,
                            'required',
                            { messages: { required: "Address is required" } },
                            { className: 'text-danger' })
                          }
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="address" class="label-text">
                            Address Line 2
                          </label>
                          <input
                            type="text"
                            id="address2"
                            name="address2"
                            class="form-control"
                            placeholder="Address line 2"
                            value={formData.address2}
                            onChange={this.handleChange}
                          />
                          {/* {this.validator.message(`Address Line 2`, formData.address2, 'required',{ className: 'text-danger' })} */}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="city" class="label-text">
                            City<span class="red-mask">*</span>
                          </label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            class="form-control"
                            placeholder="City"
                            value={formData.city}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            "City",
                            formData.city,
                            'required',
                            { messages: { required: "City is required" } },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="email" class="label-text">
                            Post Code<span class="red-mask">*</span>
                          </label>
                          <input
                            type="text"
                            id="post_code"
                            name="post_code"
                            class="form-control"
                            placeholder="Postal Code"
                            value={formData.post_code}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            "Postal Code",
                            formData.post_code,
                            'required|alpha_num_dash_space',
                            { 
                              messages: {
                                required: "Postal Code is required",
                                alpha_num_dash_space: 'Please enter a valid Postal Code with letters, numbers, dashes, or spaces'
                              }
                            },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-100pct">
                          <label for="country" class="label-text">
                            Country<span class="red-mask">*</span>
                          </label>
                          <select
                            class="form-control"
                            id="country"
                            name="country"
                            value={formData.country}
                            onChange={this.handleChange}
                          >
                            <option value="GB" selected>United Kingdom</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AX">Aland Islands</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctica</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BT">Bhutan</option>
                            <option value="BO">Bolivia</option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BV">Bouvet Island</option>
                            <option value="BR">Brazil</option>
                            <option value="IO">
                              British Indian Ocean Territory
                            </option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cape Verde</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CX">Christmas Island</option>
                            <option value="CC">Cocos (Keeling) Islands</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CG">Congo</option>
                            <option value="CD">
                              Congo, The Democratic Republic of the
                            </option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Côte d'Ivoire</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="SV">El Salvador</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Ethiopia</option>
                            <option value="FK">
                              Falkland Islands (Malvinas)
                            </option>
                            <option value="FO">Faroe Islands</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="GF">French Guiana</option>
                            <option value="PF">French Polynesia</option>
                            <option value="TF">
                              French Southern Territories
                            </option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="GY">Guyana</option>
                            <option value="HT">Haiti</option>
                            <option value="HM">
                              Heard Island and McDonald Islands
                            </option>
                            <option value="VA">
                              Holy See (Vatican City State)
                            </option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IR">Iran, Islamic Republic of</option>
                            <option value="IQ">Iraq</option>
                            <option value="IE">Ireland</option>
                            <option value="IM">Isle of Man</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KI">Kiribati</option>
                            <option value="KP">
                              Korea, Democratic People's Republic of
                            </option>
                            <option value="KR">Korea, Republic of</option>
                            <option value="KW">Kuwait</option>
                            <option value="KG">Kyrgyzstan</option>
                            <option value="LA">
                              Lao People's Democratic Republic
                            </option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libyan Arab Jamahiriya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MK">
                              Macedonia, The Former Yugoslav Republic of
                            </option>
                            <option value="MG">Madagascar</option>
                            <option value="MW">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MU">Mauritius</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexico</option>
                            <option value="FM">
                              Micronesia, Federated States of
                            </option>
                            <option value="MD">Moldova</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MA">Morocco</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NL">Netherlands</option>
                            <option value="AN">Netherlands Antilles</option>
                            <option value="NC">New Caledonia</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk Island</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="NO">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PS">
                              Palestinian Territory, Occupied
                            </option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papua New Guinea</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RE">Réunion</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russian Federation</option>
                            <option value="RW">Rwanda</option>
                            <option value="BL">Saint Barthélemy</option>
                            <option value="SH">Saint Helena</option>
                            <option value="KN">Saint Kitts and Nevis</option>
                            <option value="LC">Saint Lucia</option>
                            <option value="MF">Saint Martin</option>
                            <option value="PM">Saint Pierre and Miquelon</option>
                            <option value="VC">
                              Saint Vincent and the Grenadines
                            </option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="ST">Sao Tome and Principe</option>
                            <option value="SA">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="RS">Serbia</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="SB">Solomon Islands</option>
                            <option value="SO">Somalia</option>
                            <option value="ZA">South Africa</option>
                            <option value="GS">
                              South Georgia and the South Sandwich Islands
                            </option>
                            <option value="ES">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SD">Sudan</option>
                            <option value="SR">Suriname</option>
                            <option value="SJ">Svalbard and Jan Mayen</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="SY">Syrian Arab Republic</option>
                            <option value="TW">Taiwan, Province of China</option>
                            <option value="TJ">Tajikistan</option>
                            <option value="TZ">
                              Tanzania, United Republic of
                            </option>
                            <option value="TH">Thailand</option>
                            <option value="TL">Timor-Leste</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad and Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TM">Turkmenistan</option>
                            <option value="TC">Turks and Caicos Islands</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">Ukraine</option>
                            <option value="AE">United Arab Emirates</option>
                            <option selected="" value="GB">
                              United Kingdom
                            </option>
                            <option value="US">United States</option>
                            <option value="UM">
                              United States Minor Outlying Islands
                            </option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE">Venezuela</option>
                            <option value="VN">Viet Nam</option>
                            <option value="VG">Virgin Islands, British</option>
                            <option value="VI">Virgin Islands, U.S.</option>
                            <option value="WF">Wallis and Futuna</option>
                            <option value="EH">Western Sahara</option>
                            <option value="YE">Yemen</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabwe</option>
                          </select>
                          {this.validator.message(
                            `Country`,
                            formData.country,
                            'required',
                            { messages: { required: "Please select Country" } },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div className="_w-100pct">
                          <h2 className="text-primary">Booking Details</h2>
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="brn" class="label-text">
                            Booking Reference Number
                            <span class="red-mask">*</span>
                          </label>
                          <input
                            type="text"
                            id="booking_ref_no"
                            name="booking_ref_no"
                            class="form-control"
                            placeholder="Booking Reference No"
                            value={formData.booking_ref_no}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            `Booking Reference No`,
                            formData.booking_ref_no,
                            'required',
                            { messages: { required: "Booking Reference No is required" } },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="paid" class="label-text">
                            Total Amount to be paid <strong>( £ GBP)</strong>
                            <span class="red-mask">*</span>
                          </label>
                          <input
                            type="number"
                            id="amount"
                            name="amount"
                            required
                            class="form-control"
                            placeholder="Amount"
                            value={formData.amount}
                            onChange={this.handleChange}
                          />
                          {this.validator.message(
                            `Amount`,
                            formData.amount,
                            'required|numeric',
                            { messages: { required: "Amount is required" } },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="paid" class="label-text">
                            Select Payment Type
                            <span class="red-mask">*</span>
                          </label>
                          <select
                            class="form-control"
                            id="payment_type"
                            name="payment_type"
                            value={formData.payment_type}
                            onChange={this.handleChange}
                          >
                            <option value="Nb" selected>New Booking</option>
                            <option value="Bpay">Balance Payment</option>
                          </select>
                          {this.validator.message(
                            `Payment Type`,
                            formData.payment_type,
                            'required',
                            { messages: { required: "Please select Payment Type" } },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div className="form-group _pt-5 _pr-5 _pl-5 _w-50pct">
                          <label for="Cptype" class="label-text">
                            Select Card Type
                            <span class="red-mask">*</span>
                          </label>
                          <select
                            class="form-control"
                            id="card_type"
                            name="card_type"
                            value={formData.card_type}
                            onChange={this.handleChange}
                          >
                            <option value="Pdebit" selected>Personal Debit Card</option>
                            <option value="Pcredit">Personal Credit Card</option>
                            <option value="Icard">International Card</option>
                          </select>
                          {this.validator.message(
                            `Card Type`,
                            formData.card_type,
                            'required',
                            { messages: { required: "Please select Card Type" } },
                            { className: 'text-danger' }
                          )}
                        </div>

                        <div
                          className="terms-accepts"
                          style={{ paddingLeft: "5px" }}
                        >
                          <input
                            type="checkbox"
                            id="terms-condition"
                            name="tnc"
                            className="quick-pay-tick"
                            required
                            value={formData.tnc}
                            onChange={this.handleTncChange}
                          />
                          <label
                            for="terms-condition"
                            style={{ marginLeft: "10px", marginBottom: "0" }}
                          >
                            I understand and agree to the rules and the
                            restrictions of this fare, Booking policy and the
                            <a href="pg/teams_conditions"> Terms & Conditions </a> of Flights and Travels Ltd, for
                            any help or enquiry please call us on 0208 090 49 04
                          </label>
                        </div>
                        {this.validator.message(
                          `Terms and Condition`,
                          this.state.tnc,
                          'accepted',
                          { messages: { accepted: "Please accept Terms and Condition" } },
                          { className: 'text-danger' }
                        )}
                      </form>
                    </div>
                    <div className="py-3 bg-light">
                      <button class="btn quick-pay-btn" onClick={this.handleSubmit}>
                        <a class="p-2 font-weight-bold text-white">
                          Make Payment
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

};

export default PayOnline;
