import React, { useEffect, useState, useContext } from "react";
import { Formik, ErrorMessage, FieldArray, Field, Form } from "formik";
import TravellerService from "../../Service/TravelerService";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
import Context from "../../Context/Context";
import FlightService from "../../Service/FlightService";
import Clist from "./CountryList.json";
import FlightLoader from "../../DefaultComponent/FlightLoader";
import {notification} from "../../Shared/NotificationModal";

const PassengerDetail = (props) => {
  let flightServ = new FlightService();
  const bookedContext = useContext(Context);
  let travellerServ = new TravellerService();
  const [traveler, setTraveler] = useState([]); //list of travelers
  const [selectedTraveler, setselectedTraveler] = useState({});
  const [loading, setloading] = useState("false");

  useEffect(() => {
    async function getlist() {
      if (window.user) {
        try {
          const result = await travellerServ.getService(window.user.result.id);
          setTraveler(result);
        } catch (error) {
          setTraveler([]);
        }
      }
    }
    getlist();
  }, []);

  const handleTChange = (event) => {
    console.log("target.name", event);
  };

  const handleSubmitForm = (values, actions) => {
    let isAuth = false;

    if (!window.user) {
      bookedContext.handelModel({ type: "login", value: true });
    }
    if (window.user) {
      isAuth = true;
    }
    if (isAuth) {
      setloading("true");
      bookedContext.handelModel({ type: "login", value: true });
      let flightDetail = bookedContext.bookedFlightDetail.data;
      let userId = null;
      if (window.user) {
        userId = window.user.result.id;
      }
      let order = {
        passengerDetail: values,
        flightDetail: Object.assign({}, ...flightDetail),
        condUserId: userId,
      };
      flightServ
        .payment(order)
        .then((res) => {
            notification("success",res.message);
            setloading("false");
          props.history.push("/user/history");
        })
        .catch((err) => {
            notification("error",err);
            setloading("false");
        });
    }
  };
  let data = [];
  for (let i = 0; i < Clist.length; i++) {
    data.push(
      <option key={Clist[i].Code} value={Clist[i].Code + "," + Clist[i].Name}>
        {Clist[i].Name}
      </option>
    );
  }
  return (
    <React.Fragment>
      <FlightLoader show={loading} />
      <Formik
        handleChange={handleTChange}
        enableReinitialize={true}
        initialValues={{ ...selectedTraveler }}
        onSubmit={handleSubmitForm}
        // validationSchema={schema}

        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form>
            {bookedContext.bookedFlightDetail.data[0].AdultFares.quantity &&
              [...Array(+bookedContext.bookedFlightDetail.data[0].AdultFares.quantity).keys()].map((el, index) => (
                <>
              <div>
                <h5> Adult {index + 1}</h5>
              </div>
                <div>
                 {/*  <div className="col-md-4">
                    <h5>
                      {" "}
                      <Field
                        as="select"
                        name={`adult-title-${index}`}
                        className="form-control ng-untouched ng-pristine ng-valid"
                        onChange={(e) => {
                          handleChange(e);
                          let d = traveler.filter((el) => el.id === +e.target.value);
                          if (d.length) {
                            let obj = {};
                            let title = `adult-title-${index}`;
                            let name = `adult-name-${index}`;
                            let dob = `adult-dob-${index}`;
                            obj[title] = d[0].title;
                            obj[name] = d[0].name;
                            obj[dob] = d[0].dob;
                            setselectedTraveler((prevState) => ({ ...prevState, ...obj }));
                          }
                        }}
                      >
                        <option value="">Select Passenger</option>
                        {traveler.map((el) => {
                          if (el.passengerType == "adult") {
                            return <option value={el.id}>{el.name}</option>;
                          }
                        })}
                      </Field>
                    </h5>
                  </div> */}
                  <div class="col-8">
                  <label htmlFor="email">Email</label>
                    <Field
                      as="select"
                      className="form-control ng-untouched ng-pristine ng-valid"
                      name={`adult-title-${index}`}
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`adult-title-${index}`]}
                    >
                      <option value=""> Title </option>
                      <option value="mr"> Mr </option>
                      <option value="miss"> Miss </option>
                      <option value="mrs"> Mrs </option>
                      <option value="ms"> Ms </option>
                      <option value="master"> Master </option>
                    </Field>
                  </div>
                  <div class="col-8">
                    <Field
                      type="text"
                      name={`adult-name-${index}`}
                      className="form-control ng-untouched ng-pristine ng-valid"
                      placeholder=" Name"
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`adult-name-${index}`]}
                    />
                  </div>
                  <div class="col-8">
                    <Field
                      className="form-control ng-untouched ng-pristine ng-valid"
                      type="date"
                      name={`adult-dob-${index}`}
                      placeholder="DOB"
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`adult-dob-${index}`]}
                    />
                  </div>
                </div>
                </>
              ))}
            {bookedContext.bookedFlightDetail.data[0].ChildFares.quantity &&
              [...Array(+bookedContext.bookedFlightDetail.data[0].ChildFares.quantity).keys()].map((el, index) => (
                <div class="row form-group">
                  <div className="col-md-8">
                    <h5> Child {index + 1}</h5>
                  </div>
                  <div className="col-md-4">
                    <h5>
                      {" "}
                      <Field
                        as="select"
                        name={`child-type-${index}`}
                        className="form-control ng-untouched ng-pristine ng-valid"
                        onChange={(e) => {
                          handleChange(e);
                          let d = traveler.filter((el) => el.id === +e.target.value);
                          if (d.length) {
                            let obj = {};
                            let title = `child-title-${index}`;
                            let name = `child-name-${index}`;
                            let dob = `child-dob-${index}`;
                            obj[title] = d[0].title;
                            obj[name] = d[0].name;
                            obj[dob] = d[0].dob;
                            setselectedTraveler((prevState) => ({ ...prevState, ...obj }));
                          }
                        }}
                      >
                        <option value="">Select Passenger</option>
                        {traveler.map((el) => {
                          if (el.passengerType == "child") {
                            return <option value={el.id}>{el.name}</option>;
                          }
                        })}
                      </Field>
                    </h5>
                  </div>
                  <div class="col-4">
                    <Field
                      as="select"
                      className="form-control ng-untouched ng-pristine ng-valid"
                      name={`child-title-${index}`}
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`child-title-${index}`]}
                    >
                      <option value=""> Title </option>
                      <option value="mr"> Mr </option>
                      <option value="miss"> Miss </option>
                      <option value="mrs"> Mrs </option>
                      <option value="ms"> Ms </option>
                      <option value="master"> Master </option>
                    </Field>
                  </div>
                  <div class="col-4">
                    <Field
                      type="text"
                      name={`child-name-${index}`}
                      className="form-control ng-untouched ng-pristine ng-valid"
                      placeholder=" Name"
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`child-name-${index}`]}
                    />
                  </div>
                  <div class="col-4">
                    <Field
                      className="form-control ng-untouched ng-pristine ng-valid"
                      type="date"
                      name={`child-dob-${index}`}
                      placeholder="DOB"
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`child-dob-${index}`]}
                    />
                  </div>
                </div>
              ))}
            {bookedContext.bookedFlightDetail.data[0].InfantFares.quantity &&
              [...Array(+bookedContext.bookedFlightDetail.data[0].InfantFares.quantity).keys()].map((el, index) => (
                <div class="row form-group">
                  <div className="col-md-8">
                    <h5> Infant {index + 1}</h5>
                  </div>
                  <div className="col-md-4">
                    <h5>
                      {" "}
                      <Field
                        as="select"
                        name={`infant-type-${index}`}
                        className="form-control ng-untouched ng-pristine ng-valid"
                        onChange={(e) => {
                          handleChange(e);
                          let d = traveler.filter((el) => el.id === +e.target.value);
                          if (d.length) {
                            let obj = {};
                            let title = `infant-title-${index}`;
                            let name = `infant-name-${index}`;
                            let dob = `infant-dob-${index}`;
                            obj[title] = d[0].title;
                            obj[name] = d[0].name;
                            obj[dob] = d[0].dob;
                            setselectedTraveler((prevState) => ({ ...prevState, ...obj }));
                          }
                        }}
                      >
                        <option value="">Select Passenger</option>
                        {traveler.map((el) => {
                          if (el.passengerType == "infant") {
                            return <option value={el.id}>{el.name}</option>;
                          }
                        })}
                      </Field>
                    </h5>
                  </div>
                  <div class="col-4">
                    <Field
                      as="select"
                      className="form-control ng-untouched ng-pristine ng-valid"
                      name={`infant-title-${index}`}
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`infant-title-${index}`]}
                    >
                      <option value=""> Title </option>
                      <option value="mr"> Mr </option>
                      <option value="miss"> Miss </option>
                      <option value="mrs"> Mrs </option>
                      <option value="ms"> Ms </option>
                      <option value="master"> Master </option>
                    </Field>
                  </div>
                  <div class="col-4">
                    <Field
                      type="text"
                      name={`infant-name-${index}`}
                      className="form-control ng-untouched ng-pristine ng-valid"
                      placeholder=" Name"
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`infant-name-${index}`]}
                    />
                  </div>
                  <div class="col-4">
                    <Field
                      className="form-control ng-untouched ng-pristine ng-valid"
                      type="date"
                      name={`infant-dob-${index}`}
                      placeholder="DOB"
                      onChange={(e) => {
                        handleChange(e);
                        setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                      }}
                      value={values[`infant-dob-${index}`]}
                    />
                  </div>
                </div>
              ))}

            <h5> Contact Information </h5>
            <div class="text-secondary">
              <small>
                Please provide contact details aligned with Card which will be used for payment, these details will be
                populated on Sagepay's payment website. However you are free to change these details on Sagepay's
                payment website.
              </small>
            </div>
            <div class="row form-group">
              <div class="col-sm-6">
                <input
                  class="form-control ng-untouched ng-pristine ng-invalid"
                  formcontrolname="emailAddress"
                  matinput=""
                  name="email"
                  placeholder="Email address"
                  onChange={(e) => {
                    handleChange(e);
                    setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                  }}
                />
              </div>
              <div class="col-sm-6">
                <input
                  class="form-control ng-untouched ng-pristine ng-invalid"
                  formcontrolname="contactNumber"
                  matinput=""
                  placeholder="Contact number"
                  name="contact_number"
                  onChange={(e) => {
                    handleChange(e);
                    setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                  }}
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-6">
                <input
                  class="form-control ng-untouched ng-pristine ng-invalid"
                  formcontrolname="addressLine1"
                  matinput=""
                  placeholder="Address Line 1"
                  name="addressline1"
                  onChange={(e) => {
                    handleChange(e);
                    setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                  }}
                />
              </div>
              <div class="col-sm-6">
                <input
                  class="form-control ng-untouched ng-pristine ng-invalid"
                  formcontrolname="city"
                  matinput=""
                  placeholder="City"
                  name="city"
                  onChange={(e) => {
                    handleChange(e);
                    setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                  }}
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-6">
                <input
                  class="form-control ng-untouched ng-pristine ng-invalid"
                  formcontrolname="postalCode"
                  matinput=""
                  placeholder="Postal code"
                  name="postal_code"
                  onChange={(e) => {
                    handleChange(e);
                    setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                  }}
                />
              </div>
              <div class="col-sm-6">
                <select
                  class="form-control d-block ng-pristine ng-valid ng-touched"
                  formcontrolname="country"
                  id="Country"
                  name="country"
                  onChange={(e) => {
                    handleChange(e);
                    setselectedTraveler((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
                  }}
                >
                  <option value="GB">select</option>
                  {data}
                </select>
              </div>
            </div>
            <div class="row py-4">
              <div class="col-12">
                <button type="submit" class="btn btn-warning" style={{ backgroundColor: "#ff6c2d" }}>
                  <div class="p-2 font-weight-bold text-white">Make Payment</div>
                </button>
              </div>
            </div>
          </Form>
        )}
      />
    </React.Fragment>
  );
};
export default PassengerDetail;
