import moment from "moment";
import qs from "qs";
import React, { useState, memo, useContext } from "react";
import { Link } from "react-router-dom";
import ArrowTooltips from "../../../../Shared/Tooltip";
import { showCapitlized } from "./../helper";
import Baggage from "./Baggage";
import { CABIN_OPTIONS } from "../constants";
import SearchContext from "../../../../Context/Context";
import AirportDetails from "./AirportDetails";
import TerminalDetails from "./TerminalDetails";

const RoundFlightItem = ({
  index,
  isOffer,
  utm_campaign,
  isCallNow,
  contactNumber,
  dataLength,
  checkAdultFare,
  getAirportName,
  getAirlineName,
  handleBookButton,
  setOpenPopup = () => false,
  fareRule,
  flightList = {},
  totalLabel,
  flightClass,
  airportName,
  setIsFromFlightDetails,
  ...flight
}) => {
  const currentSearchParams = flight?.location?.search && new URLSearchParams(flight.location.search);
  const addBaggageExists = currentSearchParams?.has('addBaggage');
  let SearchContxt = useContext(SearchContext);
  const { outwardSegment = [], inwardSegment = [], cabin } = flight;
  const [showInventory, setShowInventory] = useState(false);
  const [showFareRules, setShowFareRules] = useState(false);

  const toggleInventory = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target.classList.contains("image-icons")) {
      setShowInventory(!showInventory);
      !showInventory && setShowFareRules(false);
    }
  };

  const getOperatingAirlineName = (code) => {
    if (code === "AA") {
      return "American Airlines";
    } else if (code === "BA") {
      return "British Airways";
    } else if (code === "UA") {
      return "United Airlines";
    } else {
      return code;
    }
  };
  const toggleFareRules = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFareRules(!showFareRules);
    !showFareRules && setShowInventory(false);
  };
  return (
    <>
      <div
        key={flight.id}
        className={`
                theme-search-results-item _mb-10 
                theme-search-results-item-rounded 
                theme-search-results-item ${isOffer ? "popup-card phone-deal-card" : ""
          }`}
      >
        {isOffer ? (
          <>
            <div className="ribbon ribbon-top-right">
              <span>Bargain Deal</span>
            </div>
            <div className="card-header phone-deal-card-header">
              This is Special Phone Only Deal
            </div>
          </>
        ) : null}
        <div
          className="theme-search-results-item-preview"
          onClick={toggleInventory}
          id={`flight-div-id`}
        >
          <div className="row" data-gutter="20" style={{ margin: "0" }}>
            <div
              className="col-md-10 width100"
              role="button"
              data-toggle="collapse"
              data-target={`#searchResultsItem-${index + 1}`}
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <div className="theme-search-results-item-flight-sections">
                <div className="theme-search-results-item-flight-section flight-section border-0-mobile-only">
                  <div className="row row-no-gutter row-eq-height">
                    <div className="col-xs-2 col-md-2">
                      <div
                        className="theme-search-results-item-flight-section-airline-logo-wrap"
                        style={{ textAlign: "center" }}
                      >
                        <ArrowTooltips
                          text={getAirlineName(outwardSegment[0]?.carrierCode)}
                        >
                          <>
                            <img
                              className="theme-search-results-item-flight-section-airline-logo"
                              alt="airline-logo"
                              src={`/assets/img/logos/${outwardSegment[0]?.carrierCode}.gif`}
                            />
                            <p className="airline-name-para">
                              <p>
                                {getAirlineName(outwardSegment[0]?.carrierCode)}
                              </p>
                              <p>{showCapitlized(outwardSegment[0]?.cabin)}</p>
                            </p>
                            <p style={{ color: "white", height: 0 }}>
                              {outwardSegment[0]?.class}
                            </p>
                            <p className="airline-name-para">
                              {outwardSegment[0].operatingCode !== "" ? (
                                <span>
                                  Operated by <br />
                                  {getOperatingAirlineName(
                                    outwardSegment[0]?.operatingCode
                                  )}
                                </span>
                              ) : (
                                <></>
                              )}
                            </p>
                          </>
                        </ArrowTooltips>
                      </div>
                    </div>
                    <div className="col-xs-10 col-md-10 theme-flight-list-content">
                      {/* <div className="theme-search-results-item-flight-section-item"> */}
                      <div
                        className="xs-checked-bagge row"
                        style={{ margin: "0" }}
                      >
                        <div
                          className="col-sm-3 col-xs-3 pr-0-mobile-only"
                          style={{ paddingLeft: "0" }}
                        >
                          <div className="theme-search-results-item-flight-section-meta flight-right-meta-text mr-0-mobile-only">
                            <p className="theme-search-results-item-flight-section-meta-time">
                              {moment(
                                outwardSegment[0]?.departure.dateTime
                              ).format("HH:mm")}
                            </p>
                            <p className="hidden-xs theme-search-results-item-flight-section-meta-date meta-date-text">
                              {outwardSegment?.map((el1, i1) => {
                                if (i1 === 0)
                                  return moment(el1.departure.dateTime).format(
                                    "ll"
                                  );
                              })}
                            </p>
                            <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date meta-date-text">
                              {outwardSegment?.map((el1, i1) => {
                                let dateWithoutYr = moment(
                                  el1.departure.dateTime
                                )
                                  .format("ll")
                                  .split(",")[0];
                                if (i1 === 0) return dateWithoutYr;
                              })}
                            </p>
                            <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date flight-section-airport-text">
                              {outwardSegment[0] && (
                                <ArrowTooltips
                                  text={getAirportName(
                                    outwardSegment[0]?.departure.iataCode
                                  )}
                                >
                                  <span>
                                    {outwardSegment[0]?.departure.iataCode}
                                  </span>
                                </ArrowTooltips>
                              )}
                            </p>
                          </div>
                        </div>
                        {/* <div className="hidden-xs col-sm-3 col-xs-3">
                                    <div className="theme-search-results-item-flight-section-meta">
                                      <p className="theme-search-results-item-flight-section-meta-time">
                                        {outwardSegment.map((el1, i) => {
                                          if (i == outwardSegment.length - 1) {
                                            return moment(el1.arrival.dateTime).format("HH:mm");
                                          }
                                        })}
                                      </p>
                                      <p className="theme-search-results-item-flight-section-meta-date">
                                        {outwardSegment.map((el1, i1) => {
                                          if (i1 === outwardSegment.length - 1)
                                            return moment(el1.departure.dateTime).format("ll");
                                        })}
                                      </p>
                                    </div>
                                  </div> */}
                        <div
                          className="col-sm-5 col-xs-6"
                          style={{ padding: "0" }}
                        >
                          <div className="theme-search-results-item-flight-section-path flight-search-path-section">
                            <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                              <p className="fly-time-text-content">
                                <span>
                                  {flight.outwardStop === 0
                                    ? "DIRECT"
                                    : flight.outwardStop +
                                    `${flight.outwardStop === 1
                                      ? " Stop"
                                      : " Stops"
                                    }`}{" "}
                                </span>
                                <span className="fly-time-icon-span">
                                  |{" "}
                                  <img
                                    alt="clock"
                                    src="/assets/ficon/clock.png"
                                  />
                                </span>
                                {flight.totalOutboundDuration}{" "}
                              </p>
                            </div>

                            <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                            <div className="theme-search-results-item-flight-section-path-line-start">
                              <img
                                src="/assets/ficon/airplane-take-off.png"
                                className="airplane-take-off-webonly"
                                alt="airplane-take-off"
                              />
                              <div className="theme-search-results-item-flight-section-path-line-dot hidden-xs"></div>
                              <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text hidden-xs">
                                {outwardSegment[0] && (
                                  <ArrowTooltips
                                    text={getAirportName(
                                      outwardSegment[0]?.departure.iataCode
                                    )}
                                  >
                                    <span>
                                      {outwardSegment[0]?.departure.iataCode}
                                    </span>
                                  </ArrowTooltips>
                                )}
                              </div>
                            </div>
                            {flight.outwardStop === 1 && (
                              <div className="theme-search-results-item-flight-section-path-line-middle">
                                <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon" />
                                <div className="theme-search-results-item-flight-section-path-line-dot" />
                                <div
                                  style={{ color: "#7A7E82" }}
                                  className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                >
                                  {outwardSegment[0] && (
                                    <ArrowTooltips
                                      text={getAirportName(
                                        outwardSegment[0]?.arrival.iataCode
                                      )}
                                    >
                                      <span>
                                        {outwardSegment[0]?.arrival.iataCode}
                                      </span>
                                    </ArrowTooltips>
                                  )}
                                </div>
                              </div>
                            )}

                            {flight.outwardStop === 2 && (
                              <>
                                <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                  <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                  <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                  <div
                                    style={{ color: "#7A7E82" }}
                                    className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                  >
                                    {outwardSegment[0] && (
                                      <ArrowTooltips
                                        text={getAirportName(
                                          outwardSegment[0]?.arrival.iataCode
                                        )}
                                      >
                                        <span>
                                          {outwardSegment[0]?.arrival.iataCode}
                                        </span>
                                      </ArrowTooltips>
                                    )}
                                  </div>
                                </div>
                                <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                  <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                  <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                  <div
                                    style={{ color: "#7A7E82" }}
                                    className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                  >
                                    {outwardSegment[1] && (
                                      <ArrowTooltips
                                        text={getAirportName(
                                          outwardSegment[1].arrival.iataCode
                                        )}
                                      >
                                        <span>
                                          {outwardSegment[1].arrival.iataCode}
                                        </span>
                                      </ArrowTooltips>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="theme-search-results-item-flight-section-path-line-end">
                              <img
                                src="/assets/ficon/airplane-landing.png"
                                className="airplane-take-off-webonly"
                                alt="airplane-take-off"
                              />
                              <div className="theme-search-results-item-flight-section-path-line-dot hidden-xs"></div>
                              <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text hidden-xs">
                                {outwardSegment?.[outwardSegment.length - 1] && (
                                  <ArrowTooltips
                                    text={getAirportName(
                                      outwardSegment[outwardSegment.length - 1]
                                        .arrival.iataCode
                                    )}
                                  >
                                    <span>
                                      {
                                        outwardSegment?.[
                                          outwardSegment.length - 1
                                        ]?.arrival?.iataCode
                                      }
                                    </span>
                                  </ArrowTooltips>
                                )}
                                {/* {outwardSegment.map((el1, i) => {
                                                if (i === outwardSegment.length - 1) {
                                                    return props.airportName.map((el2) => {
                                                        if (el2.iata == el1.arrival.iataCode) {
                                                            return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                        }
                                                    });
                                                }
                                            })} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-sm-3 col-xs-3 pl-0-mobile-only"
                          style={{ paddingRight: "0" }}
                        >
                          {/*{debugger}*/}
                          <div className="theme-search-results-item-flight-section-meta flight-left-meta-text ml-0-mobile-only">
                            {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                            <p className="theme-search-results-item-flight-section-meta-time">
                              {outwardSegment?.map((el1, i) => {
                                if (i === outwardSegment.length - 1) {
                                  return moment(el1.arrival.dateTime).format(
                                    "HH:mm"
                                  );
                                }
                              })}
                            </p>
                            <p className="hidden-xs theme-search-results-item-flight-section-meta-date meta-date-text">
                              {outwardSegment?.map((el1, i1) => {
                                if (i1 === outwardSegment.length - 1)
                                  return moment(el1.arrival.dateTime).format(
                                    "ll"
                                  );
                              })}
                            </p>
                            <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date meta-date-text">
                              {outwardSegment?.map((el1, i1) => {
                                let dateWithoutYr = moment(
                                  el1.departure.dateTime
                                )
                                  .format("ll")
                                  .split(",")[0];
                                if (i1 === outwardSegment.length - 1)
                                  return dateWithoutYr;
                              })}
                            </p>
                            <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date flight-section-airport-text">
                              {outwardSegment?.[outwardSegment.length - 1] && (
                                <ArrowTooltips
                                  text={getAirportName(
                                    outwardSegment[outwardSegment.length - 1]
                                      .arrival.iataCode
                                  )}
                                >
                                  <span>
                                    {
                                      outwardSegment?.[
                                        outwardSegment.length - 1
                                      ]?.arrival?.iataCode
                                    }
                                  </span>
                                </ArrowTooltips>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-1 border-left">
                          <ArrowTooltips
                            text={addBaggageExists ? "1 checked baggage allowance" :
                              outwardSegment[0]?.bagge === 0
                                ? "Hand Luggage Only"
                                : `${outwardSegment[0]?.bagge} checked baggage allowance `
                            }
                          >
                            <img
                              className="_mb-10 image-icons web-only"
                              alt="luggage"
                              src={`/assets/ficon/${outwardSegment[0]?.bagge === 0
                                ? addBaggageExists ? "luggage-blue" : "luggage-red"
                                : "luggage-blue"
                                }.png`}
                            />
                          </ArrowTooltips>
                          <ArrowTooltips text="Atol Protected">
                            <img
                              className="_mb-10 image-icons web-only"
                              alt="flight-seat"
                              src="/assets/ficon/atol_protection.png"
                              id="flight-seat"
                            />
                          </ArrowTooltips>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="theme-search-results-item-flight-section flight-section">
                  <div className="row row-no-gutter row-eq-height">
                    <div className="col-xs-2 col-md-2">
                      <div
                        className="theme-search-results-item-flight-section-airline-logo-wrap"
                        style={{ textAlign: "center" }}
                      >
                        <ArrowTooltips
                          text={getAirlineName(inwardSegment[0]?.carrierCode)}
                        >
                          <>
                            <img
                              className="theme-search-results-item-flight-section-airline-logo"
                              src={`/assets/img/logos/${inwardSegment[0]?.carrierCode}.gif`}
                              alt="Image Altern"
                            />
                            <p
                              className="airline-name-para"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <span>
                                {getAirlineName(inwardSegment[0]?.carrierCode)}
                              </span>
                              <span>
                                {showCapitlized(inwardSegment[0]?.cabin)}
                              </span>
                            </p>
                            <p style={{ color: "white", height: 0 }}>
                              {inwardSegment[0]?.class}
                            </p>
                            <p className="airline-name-para">
                              {inwardSegment[0]?.operatingCode !== "" ? (
                                <span>
                                  Operated by <br />
                                  {getOperatingAirlineName(
                                    inwardSegment[0]?.operatingCode
                                  )}
                                </span>
                              ) : (
                                <></>
                              )}
                            </p>
                          </>
                        </ArrowTooltips>
                      </div>
                    </div>
                    <div className="col-xs-10 col-md-10 theme-flight-list-content">
                      {/* <div className="theme-search-results-item-flight-section-item"> */}
                      <div
                        className="row "
                        style={{ margin: "0", justifyContent: "flex-end" }}
                      >
                        <div
                          className="col-sm-3 col-xs-3 pr-0-mobile-only"
                          style={{ paddingLeft: "0" }}
                        >
                          <div className="theme-search-results-item-flight-section-meta flight-right-meta-text mr-0-mobile-only">
                            {/* <div className="dip-arri  ">DEPARTURE TIME</div> */}
                            <p className="theme-search-results-item-flight-section-meta-time">
                              {inwardSegment?.map((el1, i) => {
                                if (i === 0) {
                                  return moment(el1.departure.dateTime).format(
                                    "HH:mm"
                                  );
                                }
                              })}
                            </p>
                            <p className="hidden-xs theme-search-results-item-flight-section-meta-date meta-date-text">
                              {inwardSegment?.map((el1, i) => {
                                if (i === 0) {
                                  return moment(el1.departure.dateTime).format(
                                    "ll"
                                  );
                                }
                              })}
                            </p>
                            <p className="hidden-lg hidden-md hidden-sm theme-search-results-item-flight-section-meta-date meta-date-text">
                              {inwardSegment?.map((el1, i) => {
                                let dateWithoutYr = moment(
                                  el1.departure.dateTime
                                )
                                  .format("ll")
                                  .split(",")[0];
                                if (i === 0) {
                                  return dateWithoutYr;
                                }
                              })}
                            </p>
                            <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date flight-section-airport-text">
                              {inwardSegment[0] && (
                                <ArrowTooltips
                                  text={getAirportName(
                                    inwardSegment[0]?.departure.iataCode
                                  )}
                                >
                                  <span>
                                    {inwardSegment[0]?.departure.iataCode}
                                  </span>
                                </ArrowTooltips>
                              )}
                            </p>
                          </div>
                        </div>
                        {/* <div className="visible-xs col-sm-3 col-xs-6">
                                    <div className="theme-search-results-item-flight-section-meta">
                                      {/* <div className="dip-arri  ">ARRIVAL TIME</div> *
                                      <p className="theme-search-results-item-flight-section-meta-time">
                                        {inwardSegment.map((el1, i) => {
                                          if (i == inwardSegment.length - 1) {
                                            return moment(el1.arrival.dateTime).format("HH:mm");
                                          }
                                        })}
                                      </p>
                                      <p className="theme-search-results-item-flight-section-meta-date">
                                        {inwardSegment.map((el1, i1) => {
                                          if (i1 === inwardSegment.length - 1)
                                            return moment(el1.arrival.dateTime).format("ll");
                                        })}
                                      </p>
                                    </div>
                                  </div> */}
                        <div
                          className="col-sm-5 col-xs-6"
                          style={{ padding: "0" }}
                        >
                          <div className="theme-search-results-item-flight-section-path flight-search-path-section">
                            <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                              <p className="fly-time-text-content">
                                <span>
                                  {flight.inwardStop === 0
                                    ? "DIRECT"
                                    : flight.inwardStop +
                                    `${flight.inwardStop === 1
                                      ? " Stop"
                                      : " Stops"
                                    }`}{" "}
                                </span>
                                <span className="fly-time-icon-span">
                                  |{" "}
                                  <img
                                    src="/assets/ficon/clock.png"
                                    alt="clock"
                                  />
                                </span>
                                {flight.totalInboundDuration}
                              </p>
                            </div>
                            <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                            <div className="theme-search-results-item-flight-section-path-line-start">
                              <img
                                src="/assets/ficon/airplane-take-off.png"
                                className="airplane-take-off-webonly"
                                alt="airplane-take-off"
                              />
                              <div className="theme-search-results-item-flight-section-path-line-dot hidden-xs"></div>
                              <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text hidden-xs">
                                {inwardSegment[0] && (
                                  <ArrowTooltips
                                    text={getAirportName(
                                      inwardSegment[0]?.departure.iataCode
                                    )}
                                  >
                                    <span>
                                      {inwardSegment[0]?.departure.iataCode}
                                    </span>
                                  </ArrowTooltips>
                                )}
                              </div>
                            </div>
                            {flight.inwardStop === 1 && (
                              <div className="theme-search-results-item-flight-section-path-line-middle">
                                <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                <div
                                  style={{ color: "#7A7E82" }}
                                  className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                >
                                  {inwardSegment[0] && (
                                    <ArrowTooltips
                                      text={getAirportName(
                                        inwardSegment[0]?.arrival.iataCode
                                      )}
                                    >
                                      <span>
                                        {inwardSegment[0]?.arrival.iataCode}
                                      </span>
                                    </ArrowTooltips>
                                  )}
                                </div>
                              </div>
                            )}

                            {flight.inwardStop === 2 && (
                              <>
                                <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                  <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                  <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                  <div
                                    style={{ color: "#7A7E82" }}
                                    className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                  >
                                    {inwardSegment[0] && (
                                      <ArrowTooltips
                                        text={getAirportName(
                                          inwardSegment[0]?.arrival.iataCode
                                        )}
                                      >
                                        <span>
                                          {inwardSegment[0]?.arrival.iataCode}
                                        </span>
                                      </ArrowTooltips>
                                    )}
                                  </div>
                                </div>
                                <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                  <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                  <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                  <div
                                    style={{ color: "#7A7E82" }}
                                    className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                  >
                                    {inwardSegment[1] && (
                                      <ArrowTooltips
                                        text={getAirportName(
                                          inwardSegment[1].arrival.iataCode
                                        )}
                                      >
                                        <span>
                                          {inwardSegment[1].arrival.iataCode}
                                        </span>
                                      </ArrowTooltips>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="theme-search-results-item-flight-section-path-line-end">
                              <img
                                src="/assets/ficon/airplane-landing.png"
                                className="airplane-take-off-webonly"
                                alt="airplane-take-off"
                              />
                              <div className="theme-search-results-item-flight-section-path-line-dot dot-line-mob hidden-xs"></div>
                              <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text hidden-xs">
                                {inwardSegment?.[inwardSegment.length - 1] && (
                                  <ArrowTooltips
                                    text={getAirportName(
                                      inwardSegment[inwardSegment.length - 1]
                                        .arrival.iataCode
                                    )}
                                  >
                                    <span>
                                      {
                                        inwardSegment?.[inwardSegment.length - 1]
                                          .arrival.iataCode
                                      }
                                    </span>
                                  </ArrowTooltips>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-sm-3 col-xs-3 pl-0-mobile-only"
                          style={{ paddingRight: "0" }}
                        >
                          <div className="theme-search-results-item-flight-section-meta flight-left-meta-text ml-0-mobile-only">
                            {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                            <p className="theme-search-results-item-flight-section-meta-time">
                              {inwardSegment?.map((el1, i) => {
                                if (i === inwardSegment.length - 1) {
                                  return moment(el1.arrival.dateTime).format(
                                    "HH:mm"
                                  );
                                }
                              })}
                            </p>
                            <p className="hidden-xs theme-search-results-item-flight-section-meta-date meta-date-text">
                              {inwardSegment?.map((el1, i1) => {
                                if (i1 === inwardSegment.length - 1)
                                  return moment(el1.arrival.dateTime).format(
                                    "ll"
                                  );
                              })}
                            </p>
                            <p className="hidden-lg hidden-md hidden-sm theme-search-results-item-flight-section-meta-date meta-date-text">
                              {inwardSegment?.map((el1, i1) => {
                                let dateWithoutYr = moment(el1.arrival.dateTime)
                                  .format("ll")
                                  .split(",")[0];
                                if (i1 === inwardSegment.length - 1)
                                  return dateWithoutYr;
                              })}
                            </p>
                            <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date flight-section-airport-text">
                              {inwardSegment?.[inwardSegment.length - 1] && (
                                <ArrowTooltips
                                  text={getAirportName(
                                    inwardSegment[inwardSegment.length - 1]
                                      .arrival.iataCode
                                  )}
                                >
                                  <span>
                                    {
                                      inwardSegment?.[inwardSegment.length - 1]
                                        .arrival.iataCode
                                    }
                                  </span>
                                </ArrowTooltips>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-1 border-left">
                          <ArrowTooltips
                            text={addBaggageExists ? "1 checked baggage allowance" :
                              inwardSegment[0]?.bagge === 0
                                ? "Hand Luggage Only"
                                : `${inwardSegment[0]?.bagge} checked baggage allowance `
                            }
                          >
                            <img
                              className="_mb-10 image-icons web-only"
                              alt="luggage"
                              src={`/assets/ficon/${inwardSegment[0]?.bagge === 0
                                ? addBaggageExists ? "luggage-blue" : "luggage-red"
                                : "luggage-blue"
                                }.png`}
                            />
                          </ArrowTooltips>
                          <ArrowTooltips text="Atol Protected">
                            <img
                              className="_mb-10 image-icons web-only"
                              src="/assets/ficon/atol_protection.png"
                              alt="flight-seat"
                              id="flight-seat"
                            />
                          </ArrowTooltips>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="col-xs-12 text-center">
                      <span className="cancellation-chip">
                        SAME DAY FREE CANCELLATION
                      </span>
                      {outwardSegment[0]?.bagge !== 0 && (
                        <div className="bagg-allowance">
                          This fare includes {outwardSegment[0]?.bagge + " "}
                          checked-in luggage
                          {outwardSegment[0]?.bagge === 1 ? " of 23 kg " : " "}
                          {outwardSegment[0]?.bagge === 2 ? " of 23 kg " : " "}
                          plus 7-8 kg hand baggage per person.
                        </div>
                      )}
                      {addBaggageExists && (
                        <div className="st-bagg-allowance">
                          This fare includes 1 checked-in luggage of 23 kg plus 7-8 kg hand baggage per person.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Div Closed */}
              </div>
            </div>
            <div className="col-md-2 col-xs-2 width100 border-left">
              <div className="theme-search-results-item-book flight-book">
                <div className="row mobile-only">
                  <div className="col-xs-7 pl-0-mobile-only">
                    <p className="book-price price-left">{totalLabel}</p>
                    <div className="theme-search-results-item-price price-left">
                      {isCallNow ? (
                        <p className="theme-search-results-item-from-sign">
                          {"from...."}
                        </p>
                      ) : (
                        <p className="theme-search-results-item-price-sign"></p>
                      )}
                      <p className="theme-search-results-item-price-tag">
                        £ {parseFloat(checkAdultFare(flight)).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-5 pr-0-mobile-only">
                    {dataLength > 1 || window.location.pathname === "/search" ? (
                      <>
                        {isOffer || isCallNow ? (
                          <>
                            <div
                              className="btn btn-secondary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                              style={{ cursor: "default" }}
                            >
                              Call Us <br />
                              {contactNumber}
                            </div>
                          </>
                        ) : (
                          <>
                            <Link
                              className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn"
                              onClick={(e) => handleBookButton(e, flight)}
                              onContextMenu={(e) => e.preventDefault()}
                              to={{
                                pathname: "/flightDetails",
                                search: `?${qs.stringify({
                                  getFlightInfo: {
                                    itemref: flight.itemref,
                                    outavail: flight.outavail,
                                    inbavail: flight.inbavail,
                                    session_id: flight.session_id,
                                    searchinfo: {
                                      from: flight.from,
                                      to: flight.to,
                                      trip: (SearchContxt?.searchReasult?.to1 && SearchContxt?.searchReasult?.from1) ? "MultiTrip" : "RoundTrip",
                                      utm_campaign: utm_campaign
                                        ? utm_campaign
                                        : "localSearch",
                                      clientRef: flight.clientRef,
                                      cabin: cabin || CABIN_OPTIONS[0].value,
                                    },
                                  },
                                })}`,
                              }}
                            >
                              SELECT
                            </Link>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="web-only">
                  <p className="book-price">{totalLabel}</p>
                  <div className="theme-search-results-item-price">
                    {isCallNow ? (
                      <p className="theme-search-results-item-from-sign">
                        {"from...."}
                      </p>
                    ) : (
                      <p className="theme-search-results-item-price-sign"></p>
                    )}
                    <p className="theme-search-results-item-price-tag">
                      £ {parseFloat(checkAdultFare(flight)).toFixed(2)}
                    </p>
                  </div>
                  {dataLength > 1 || window.location.pathname === "/search" ? (
                    <>
                      {isOffer || isCallNow ? (
                        <>
                          <div
                            className="btn btn-secondary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                            style={{ cursor: "default" }}
                          >
                            Call Us <br />
                            {contactNumber}
                          </div>
                        </>
                      ) : (
                        // <a
                        //   className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                        //   onClick={(e) => {
                        //     setOpenPopup(true);
                        //     e.stopPropagation();
                        //   }}
                        // >
                        //   Call Now
                        // </a>
                        // <a
                        //   href={`tel:${contactNumber}`}
                        //   className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                        //   onClick={(e) => {
                        //     //     setOpenPopup(true);
                        //     // e.stopPropagation();
                        //   }}
                        // >
                        //   Call Us <br />
                        //   {contactNumber}
                        // </a>
                        <>
                          <Link
                            className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn"
                            onClick={(e) => handleBookButton(e, flight)}
                            onContextMenu={(e) => e.preventDefault()}
                            to={{
                              pathname: "/flightDetails",
                              search: `?${qs.stringify({
                                getFlightInfo: {
                                  itemref: flight.itemref,
                                  outavail: flight.outavail,
                                  inbavail: flight.inbavail,
                                  session_id: flight.session_id,
                                  searchinfo: {
                                    from: flight.from,
                                    to: flight.to,
                                    trip: (SearchContxt?.searchReasult?.to1 && SearchContxt?.searchReasult?.from1) ? "MultiTrip" : "RoundTrip",
                                    utm_campaign: utm_campaign
                                      ? utm_campaign
                                      : "localSearch",
                                    clientRef: flight.clientRef,
                                    cabin: cabin || CABIN_OPTIONS[0].value,
                                  },
                                },
                              })}`,
                            }}
                          >
                            SELECT
                          </Link>
                        </>
                      )}
                    </>
                  ) : null}
                </div>

                {isOffer || isCallNow ? (
                  <div className="left_seat ml-0-mobile-only">
                    {/*<img
                                  src={"/assets/ficon/limited_availability.png"}
                                />*/}
                    {/* Limited Availibility */}
                  </div>
                ) : (
                  flight?.seats && (
                    <div className="left_seat ml-0-mobile-only">
                      {`${flight.seats}`} Seats Left
                    </div>
                  )
                )}
                <div
                  className="iter iter-round itenerary-text cursor"
                  data-toggle="collapse"
                  id="flight-div-id"
                  data-target={`#searchResultsItem-${index + 1}`}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  onClick={toggleInventory}
                  role="button"
                >
                  <a>Flight Details</a>
                </div>
                {fareRule?.length && (
                  <div
                    className="iter iter-round itenerary-text cursor"
                    data-toggle="collapse"
                    data-target={`#rule-collapse-${index + 1}`}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={toggleFareRules}
                  >
                    <a>Show Fare Rules</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showFareRules && (
          <div
            id={`rule-collapse-${index + 1}`}
            className=" fare-rules-collapse"
            aria-expanded="true"
          >
            <div className="theme-search-results-item-extend-inner extend-inner-padding">
              {fareRule?.length
                ? fareRule.map((rule) => (
                  <span>
                    {rule}
                    <br />
                  </span>
                ))
                : ""}
            </div>
          </div>
        )}

        {showInventory && (
          <div
            className="theme-search-results-item-collapse "
            id={`searchResultsItem-${index + 1}`}
          // aria-expanded="true"
          // style={
          //   dataLength >= 1 && props?.match?.url
          //     ? { height: "0px" }
          //     : { height: "auto" }
          // }
          >
            <div className="theme-search-results-item-extend">
              {dataLength > 1 && (
                <a
                  className="theme-search-results-item-extend-close"
                  // href={`#searchResultsItem-${index + 1}`}
                  role="button"
                  // data-toggle="collapse"
                  // aria-expanded="true"
                  // aria-controls={`searchResultsItem-${index + 1}`}
                  onClick={() => setShowInventory(false)}
                >
                  ✕
                </a>
              )}
              <div className="theme-search-results-item-extend-inner extend-inner-padding">
                <div className="theme-search-results-item-flight-detail-items">
                  <div className="theme-search-results-item-flight-details">
                    <div className="row">
                      {/* <div className="col-md-11" style={{boxShadow: "0 1px 4px 0 rgba(16,35,49,.15)", margin: "auto", padding: "0"}}> */}
                      <div className="col-md-11 inner_outbound width100">
                        <img
                          src={`/assets/ficon/outbound.png`}
                          alt="outbound"
                        />
                        <span className="inner-outbound-text">Departure</span> [{" "}
                        <strong>
                          {getAirportName(
                            outwardSegment[0]?.departure.iataCode,
                            "name"
                          )}
                        </strong>{" "}
                        to{" "}
                        <strong>
                          {getAirportName(
                            inwardSegment[0]?.departure.iataCode,
                            "name"
                          )}
                        </strong>{" "}
                        ]
                      </div>
                      <div className="col-md-11 inner_detail width100">
                        {window.innerWidth <= 600 &&
                          <AirportDetails
                            airLineName={outwardSegment[0]?.carrierCode}
                            gifCode={outwardSegment[0]?.carrierCode}
                            carrierCode={outwardSegment[0]?.carrierCode}
                            flightNumber={outwardSegment[0]?.flight_number}
                            operatingCode={outwardSegment[0]?.operatingCode}
                            requiredWard={outwardSegment}
                            cabin={outwardSegment[0]?.cabin}
                            bagge={outwardSegment?.[0]?.bagge}
                            addBaggageExists={addBaggageExists}
                            getOperatingAirlineName={getOperatingAirlineName}
                          />
                        }
                        <div className="row">
                          {window.innerWidth > 600 &&
                            <div className="col-sm-2 col-xs-2 inner_logo_block">
                              <div className="inner_logo_div">
                                <ArrowTooltips
                                  text={getAirlineName(
                                    outwardSegment[0]?.carrierCode
                                  )}
                                >
                                  <img
                                    src={`/assets/img/logos/${outwardSegment[0]?.carrierCode}.gif`}
                                    className="inner_logo"
                                    alt="outbound"
                                  />
                                </ArrowTooltips>
                              </div>
                              <div className="airport theme-search-result-airport-name">
                                {outwardSegment &&
                                  `${outwardSegment[0]?.carrierCode} ${outwardSegment[0]?.flight_number}`}
                                <p>
                                  {getAirlineName(outwardSegment[0]?.carrierCode)}
                                </p>
                                <p>{showCapitlized(outwardSegment[0]?.cabin)}</p>
                                {outwardSegment[0]?.operatingCode !== "" ? (
                                  <>
                                    <br />
                                    <p className="fit-content">
                                      Operated by <br />
                                      {getOperatingAirlineName(
                                        outwardSegment[0]?.operatingCode
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          }
                          <div className="col-sm-3 col-xs-4 inner_from theme-flight-list-items">
                            <div className="time">
                              {outwardSegment?.map((el1, i) => {
                                if (i === 0) {
                                  return moment(el1.departure.dateTime).format(
                                    "HH:mm"
                                  );
                                }
                              })}
                            </div>
                            <div className="location">
                              {outwardSegment[0] && (
                                <ArrowTooltips
                                  text={getAirportName(
                                    outwardSegment[0]?.departure.iataCode
                                  )}
                                >
                                  <span>{`${getAirportName(
                                    outwardSegment[0]?.departure.iataCode,
                                    "name"
                                  )}(${outwardSegment[0]?.departure.iataCode
                                    })`}</span>
                                </ArrowTooltips>
                              )}
                            </div>
                            <div className="date">
                              {outwardSegment?.map((el1, i) => {
                                if (i === 0) {
                                  return moment(el1.departure.dateTime).format(
                                    "ll"
                                  );
                                }
                              })}
                            </div>
                            <TerminalDetails terminal={outwardSegment[0]?.departure?.terminal} />
                          </div>
                          <div className="col-sm-3 col-xs-4 xs-0 theme-flight-list-items">
                            <img
                              className="inner_arrow"
                              alt="inner_arrow"
                              src="/assets/ficon/inner-clock.png"
                            />
                            <div className="inner_duration">
                              {outwardSegment?.map((el1, i) => {
                                if (i === 0) {
                                  return el1.duration;
                                }
                              })}
                            </div>
                          </div>
                          <div className="col-sm-3 col-xs-4 inner_to theme-flight-list-items">
                            <div className="time">
                              {outwardSegment?.map((el1, i) => {
                                if (i === 0) {
                                  return moment(el1.arrival.dateTime).format(
                                    "HH:mm"
                                  );
                                }
                              })}
                            </div>
                            <div className="location">
                              {outwardSegment[0] && (
                                <ArrowTooltips
                                  text={getAirportName(
                                    outwardSegment[0]?.arrival.iataCode
                                  )}
                                >
                                  <span>{`${getAirportName(
                                    outwardSegment[0]?.arrival.iataCode,
                                    "name"
                                  )}(${outwardSegment[0]?.arrival.iataCode
                                    })`}</span>
                                </ArrowTooltips>
                              )}
                            </div>
                            <div className="date">
                              {moment(
                                outwardSegment[0]?.arrival.dateTime
                              ).format("ll")}
                            </div>
                            <TerminalDetails terminal={outwardSegment[0]?.arrival?.terminal} />
                          </div>
                          {/* <div className="col-sm-1 hidden-xs">
                            <div className="inner_icons">
                              <ArrowTooltips text="Seat">
                                <img
                                  className="inner_image-icons"
                                  alt="inner_image-icons"
                                  style={{ marginBottom: "5px" }}
                                  src="/assets/ficon/flight-seat.png"
                                />
                              </ArrowTooltips>
                              <ArrowTooltips text="Wi-Fi">
                                <img
                                  className="inner_image-icons"
                                  alt="inner_image"
                                  style={{ marginBottom: "5px" }}
                                  src="/assets/ficon/wi-fi.png"
                                />
                              </ArrowTooltips>
                              <ArrowTooltips text="Video">
                                <img
                                  className="inner_image-icons"
                                  alt="inner_image"
                                  style={{ marginBottom: "5px" }}
                                  src="/assets/ficon/video.png"
                                />
                              </ArrowTooltips>
                              <ArrowTooltips text="Power Outlet">
                                <img
                                  className="inner_image-icons"
                                  alt="inner_image"
                                  style={{ marginBottom: "5px" }}
                                  src="/assets/ficon/electrical.png"
                                />
                              </ArrowTooltips>
                              <ArrowTooltips text="Snacks">
                                <img
                                  alt="inner_image"
                                  className="inner_image-icons"
                                  src="/assets/ficon/food.png"
                                />
                              </ArrowTooltips>
                            </div>
                          </div> */}
                        </div>
                        {window.innerWidth > 600 && <Baggage bagge={addBaggageExists ? 1 : outwardSegment?.[0]?.bagge} />}
                      </div>
                      {outwardSegment?.map((el1, im) => {
                        if (im > 0) {
                          return (
                            <>
                              <div className="col-md-11 width100 inner_middle-layover">
                                <div className="row inner_layover layover-div">
                                  <div className="col-md-8 m_auto-layover m_auto_change_webonly">
                                    <img
                                      src="/assets/ficon/exchange.png"
                                      className="inner_exchange"
                                      alt="inner_exchange"
                                    />
                                    <span className="inner_changeinfo">
                                      Flight Change at{" "}
                                      {getAirportName(
                                        el1.departure.iataCode,
                                        "name"
                                      )}
                                      ({el1.departure.iataCode})
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-xs-12 m_auto-layover m_auto_layover_webonly">
                                    <span className="inner_layover_time">
                                      Layover of{" "}
                                      {(() => {
                                        let startTime = new Date(
                                          outwardSegment[
                                            im - 1
                                          ].arrival.dateTime
                                        ).getTime();
                                        let endTime = new Date(
                                          el1.departure.dateTime
                                        );
                                        let diff = +(endTime - startTime);
                                        // return moment(diff / 1000).format("HH:mm");
                                        let d = moment
                                          .utc(diff)
                                          .format("H:mm ");
                                        let formatedDate = d.split(":");
                                        if (formatedDate.length === 1) {
                                          return `${formatedDate[0]?.trim()}M`;
                                        } else if (formatedDate.length === 2) {
                                          return `${formatedDate[0]
                                            }h ${formatedDate[1].trim()}m`;
                                        }
                                      })()}
                                    </span>
                                    <img
                                      src="/assets/ficon/timer.png"
                                      className="inner_timer"
                                      alt="inner_timer"
                                    />
                                  </div>
                                </div>
                                <div className="inner_divider"></div>
                              </div>
                              <div className="col-md-11 inner_detail width100">
                                {window.innerWidth <= 600 &&
                                  <AirportDetails
                                    airLineName={outwardSegment[0]?.carrierCode}
                                    gifCode={el1?.carrierCode}
                                    carrierCode={el1.carrierCode}
                                    flightNumber={el1.flight_number}
                                    operatingCode={outwardSegment[0]?.operatingCode}
                                    requiredWard={outwardSegment}
                                    cabin={el1?.cabin}
                                    bagge={el1?.bagge}
                                    addBaggageExists={addBaggageExists}
                                    getOperatingAirlineName={getOperatingAirlineName}
                                  />
                                }
                                <div className="row">
                                  {window.innerWidth > 600 &&
                                    <div className="col-sm-2 col-xs-2 inner_logo_block">
                                      <div className="inner_logo_div">
                                        <ArrowTooltips
                                          text={getAirlineName(
                                            outwardSegment[0]?.carrierCode
                                          )}
                                        >
                                          <img
                                            src={`/assets/img/logos/${el1.carrierCode}.gif`}
                                            className="inner_logo"
                                            alt="inner_logo"
                                          />
                                        </ArrowTooltips>
                                      </div>
                                      <div className="airport theme-search-result-airport-name">
                                        {outwardSegment &&
                                          `${el1.carrierCode} ${el1.flight_number}`}
                                        <p>
                                          {getAirlineName(
                                            outwardSegment[0]?.carrierCode
                                          )}
                                        </p>
                                        <p>{showCapitlized(el1?.cabin)}</p>
                                        {outwardSegment[0]?.operatingCode !==
                                          "" ? (
                                          <>
                                            <br />
                                            <p className="fit-content">
                                              Operated by <br />
                                              {getOperatingAirlineName(
                                                outwardSegment[0]?.operatingCode
                                              )}
                                            </p>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  }
                                  <div className="col-sm-3 col-xs-4 inner_from theme-flight-list-items">
                                    <div className="time">
                                      {moment(el1.departure.dateTime).format(
                                        "HH:mm"
                                      )}
                                    </div>
                                    <div className="location">
                                      <ArrowTooltips
                                        text={getAirportName(
                                          el1.departure.iataCode
                                        )}
                                      >
                                        <span>{`${getAirportName(
                                          el1.departure.iataCode,
                                          "name"
                                        )}(${el1.departure.iataCode})`}</span>
                                      </ArrowTooltips>
                                    </div>
                                    <div className="date">
                                      {moment(el1.departure.dateTime).format(
                                        "ll"
                                      )}
                                    </div>
                                    <TerminalDetails terminal={el1.departure?.terminal} />
                                  </div>
                                  <div className="col-sm-3 col-xs-4 xs-0 theme-flight-list-items">
                                    <img
                                      className="inner_arrow"
                                      alt="inner_arrow"
                                      src="/assets/ficon/inner-clock.png"
                                    />
                                    <div className="inner_duration">
                                      {el1.duration}
                                    </div>
                                  </div>
                                  <div className="col-sm-3 col-xs-4 inner_to theme-flight-list-items">
                                    <div className="time">
                                      {moment(el1.arrival.dateTime).format(
                                        "HH:mm"
                                      )}
                                    </div>
                                    <div className="location">
                                      <ArrowTooltips
                                        text={getAirportName(
                                          el1.arrival.iataCode
                                        )}
                                      >
                                        <span>{`${getAirportName(
                                          el1.arrival.iataCode,
                                          "name"
                                        )}(${el1.arrival.iataCode})`}</span>
                                      </ArrowTooltips>
                                    </div>
                                    <div className="date">
                                      {moment(el1.arrival.dateTime).format(
                                        "ll"
                                      )}
                                    </div>
                                    <TerminalDetails terminal={el1?.arrival?.terminal} />
                                  </div>
                                  {/* <div className="col-sm-1 hidden-xs">
                                    <div className="inner_icons">
                                      <ArrowTooltips text="Seat">
                                        <img
                                          className="inner_image-icons"
                                          style={{
                                            marginBottom: "5px",
                                          }}
                                          src="/assets/ficon/flight-seat.png"
                                          alt="flight-seat"
                                        />
                                      </ArrowTooltips>
                                      <ArrowTooltips text="Wi-fi">
                                        <img
                                          className="inner_image-icons"
                                          style={{
                                            marginBottom: "5px",
                                          }}
                                          src="/assets/ficon/wi-fi.png"
                                          alt="wi-fi"
                                        />
                                      </ArrowTooltips>
                                      <ArrowTooltips text="Video">
                                        <img
                                          className="inner_image-icons"
                                          style={{
                                            marginBottom: "5px",
                                          }}
                                          src="/assets/ficon/video.png"
                                          alt="video"
                                        />
                                      </ArrowTooltips>
                                      <ArrowTooltips text="Power Outlet">
                                        <img
                                          className="inner_image-icons"
                                          style={{
                                            marginBottom: "5px",
                                          }}
                                          src="/assets/ficon/electrical.png"
                                          alt="electrical"
                                        />
                                      </ArrowTooltips>
                                      <ArrowTooltips text="Snacks">
                                        <img
                                          className="inner_image-icons"
                                          src="/assets/ficon/food.png"
                                          alt="food"
                                        />
                                      </ArrowTooltips>
                                    </div>
                                  </div> */}
                                </div>
                                {window.innerWidth > 600 && <Baggage bagge={addBaggageExists ? 1 : el1?.bagge} />}
                              </div>
                            </>
                          );
                        }
                      })}
                    </div>
                    {/* <hr/> */}
                  </div>
                  {/* return */}
                  <div className="row">
                    <div className="col-md-11 inner_outbound width100">
                      <img
                        src={`/assets/ficon/inbound.png`}
                        alt="inbound"
                        style={{ transform: "scaleX(-1)" }}
                      />
                      <span className="inner-outbound-text">Arrival</span> [{" "}
                      <strong>
                        {getAirportName(
                          inwardSegment[0]?.departure.iataCode,
                          "name"
                        )}
                      </strong>{" "}
                      to{" "}
                      <strong>
                        {getAirportName(
                          outwardSegment[0]?.departure.iataCode,
                          "name"
                        )}
                      </strong>{" "}
                      ]
                    </div>
                    <div className="col-md-11 inner_detail width100">
                      {window.innerWidth <= 600 &&
                        <AirportDetails
                          airLineName={inwardSegment[0]?.carrierCode}
                          gifCode={inwardSegment[0]?.carrierCode}
                          carrierCode={inwardSegment[0]?.carrierCode}
                          flightNumber={inwardSegment[0]?.flight_number}
                          operatingCode={inwardSegment[0]?.operatingCode}
                          requiredWard={inwardSegment}
                          cabin={inwardSegment[0]?.cabin}
                          bagge={inwardSegment?.[0]?.bagge}
                          addBaggageExists={addBaggageExists}
                          getOperatingAirlineName={getOperatingAirlineName}
                        />
                      }
                      <div className="row">
                        {window.innerWidth > 600 &&
                          <div className="col-sm-2 col-xs-2 inner_logo_block">
                            <div className="inner_logo_div">
                              <ArrowTooltips
                                text={getAirlineName(
                                  inwardSegment[0]?.carrierCode
                                )}
                              >
                                <img
                                  src={`/assets/img/logos/${inwardSegment[0]?.carrierCode}.gif`}
                                  className="inner_logo"
                                  alt="inner_logo"
                                />
                              </ArrowTooltips>
                            </div>
                            <div className="airport theme-search-result-airport-name">
                              {inwardSegment &&
                                `${inwardSegment[0]?.carrierCode} ${inwardSegment[0]?.flight_number}`}
                              <p>
                                {getAirlineName(inwardSegment[0]?.carrierCode)}
                              </p>
                              <p>{showCapitlized(inwardSegment[0]?.cabin)}</p>
                              {inwardSegment[0]?.operatingCode !== "" ? (
                                <>
                                  <br />
                                  <p className="fit-content">
                                    Operated by <br />
                                    {getOperatingAirlineName(
                                      inwardSegment[0]?.operatingCode
                                    )}
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        }
                        <div className="col-sm-3 col-xs-4 inner_from theme-flight-list-items">
                          <div className="time">
                            {inwardSegment?.map((el1, i) => {
                              if (i === 0) {
                                return moment(el1.departure.dateTime).format(
                                  "HH:mm"
                                );
                              }
                            })}
                          </div>
                          <div className="location">
                            {inwardSegment[0] && (
                              <ArrowTooltips
                                text={getAirportName(
                                  inwardSegment[0]?.departure.iataCode
                                )}
                              >
                                <span>{`${getAirportName(
                                  inwardSegment[0]?.departure.iataCode,
                                  "name"
                                )}(${inwardSegment[0]?.departure.iataCode
                                  })`}</span>
                              </ArrowTooltips>
                            )}
                          </div>
                          <div className="date">
                            {inwardSegment?.map((el1, i) => {
                              if (i === 0) {
                                return moment(el1.departure.dateTime).format(
                                  "ll"
                                );
                              }
                            })}
                          </div>
                          <TerminalDetails terminal={inwardSegment[0]?.departure?.terminal} />
                        </div>
                        <div className="col-sm-3 col-xs-4 xs-0 theme-flight-list-items">
                          <img
                            className="inner_arrow"
                            src="/assets/ficon/inner-clock.png"
                            alt="clock"
                          />
                          <div className="inner_duration">
                            {inwardSegment?.map((el1, i) => {
                              if (i === 0) {
                                return el1.duration;
                              }
                            })}
                          </div>
                        </div>
                        <div className="col-sm-3 col-xs-4 inner_to theme-flight-list-items">
                          <div className="time">
                            {inwardSegment?.map((el1, i) => {
                              if (i === 0) {
                                return moment(el1.arrival.dateTime).format(
                                  "HH:mm"
                                );
                              }
                            })}
                          </div>
                          <div className="location">
                            {inwardSegment[0] && (
                              <ArrowTooltips
                                text={getAirportName(
                                  inwardSegment[0]?.arrival.iataCode
                                )}
                              >
                                <span>{`${getAirportName(
                                  inwardSegment[0]?.arrival.iataCode,
                                  "name"
                                )}(${inwardSegment[0]?.arrival.iataCode
                                  })`}</span>
                              </ArrowTooltips>
                            )}
                          </div>
                          <div className="date">
                            {moment(inwardSegment[0]?.arrival.dateTime).format(
                              "ll"
                            )}
                          </div>
                          <TerminalDetails terminal={inwardSegment[0]?.arrival?.terminal} />
                        </div>
                        {/* <div className="col-sm-1 hidden-xs">
                          <div className="inner_icons">
                            <ArrowTooltips text="Seat">
                              <img
                                className="inner_image-icons"
                                style={{ marginBottom: "5px" }}
                                src="/assets/ficon/flight-seat.png"
                                alt="flight-seat"
                              />
                            </ArrowTooltips>
                            <ArrowTooltips text="Wi-Fi">
                              <img
                                className="inner_image-icons"
                                style={{ marginBottom: "5px" }}
                                src="/assets/ficon/wi-fi.png"
                                alt="wi-fi"
                              />
                            </ArrowTooltips>
                            <ArrowTooltips text="Video">
                              <img
                                className="inner_image-icons"
                                style={{ marginBottom: "5px" }}
                                src="/assets/ficon/video.png"
                                alt="video"
                              />
                            </ArrowTooltips>
                            <ArrowTooltips text="Power Outlet">
                              <img
                                className="inner_image-icons"
                                style={{ marginBottom: "5px" }}
                                src="/assets/ficon/electrical.png"
                                alt="electrical"
                              />
                            </ArrowTooltips>
                            <ArrowTooltips text="Snacks">
                              <img
                                className="inner_image-icons"
                                src="/assets/ficon/food.png"
                                alt="food"
                              />
                            </ArrowTooltips>
                          </div>
                        </div> */}
                      </div>
                      {window.innerWidth > 600 && <Baggage bagge={addBaggageExists ? 1 : inwardSegment?.[0]?.bagge} />}
                    </div>
                    {inwardSegment?.map((el1, im) => {
                      if (im > 0) {
                        return (
                          <>
                            <div className="col-md-11 width100 inner_middle-layover">
                              <div className="row inner_layover layover-div">
                                <div className="col-md-8 m_auto-layover m_auto_change_webonly">
                                  <img
                                    src="/assets/ficon/exchange.png"
                                    className="inner_exchange"
                                    alt="inner_exchange"
                                  />
                                  <span className="inner_changeinfo">
                                    Flight Change at{" "}
                                    {airportName.map((el2) => {
                                      if (el2.iata === el1.departure.iataCode) {
                                        return el2.name;
                                      }
                                    })}{" "}
                                    ({el1.departure.iataCode})
                                  </span>
                                </div>
                                <div className="col-md-4 col-xs-12 m_auto-layover m_auto_layover_webonly">
                                  <span className="inner_layover_time">
                                    Layover of{" "}
                                    {(() => {
                                      let startTime = new Date(
                                        inwardSegment[im - 1].arrival.dateTime
                                      ).getTime();
                                      let endTime = new Date(
                                        el1.departure.dateTime
                                      );
                                      let diff = +(endTime - startTime);
                                      // return moment(diff / 1000).format("HH:mm");
                                      let d = moment.utc(diff).format("H:mm ");
                                      let formatedDate = d.split(":");
                                      if (formatedDate.length === 1) {
                                        return `${formatedDate[0]?.trim()}M`;
                                      } else if (formatedDate.length === 2) {
                                        return `${formatedDate[0]
                                          }h ${formatedDate[1].trim()}m`;
                                      }
                                    })()}
                                  </span>
                                  <img
                                    src="/assets/ficon/timer.png"
                                    className="inner_timer"
                                    alt="inner_timer"
                                  />
                                </div>
                              </div>
                              <div className="inner_divider" />
                            </div>
                            <div className="col-md-11 width100 inner_detail">
                              {window.innerWidth <= 600 &&
                                <AirportDetails
                                  airLineName={el1?.carrierCode}
                                  gifCode={el1?.carrierCode}
                                  carrierCode={el1?.carrierCode}
                                  flightNumber={el1?.flight_number}
                                  operatingCode={el1?.operatingCode}
                                  requiredWard={outwardSegment}
                                  cabin={el1?.cabin}
                                  bagge={el1?.bagge}
                                  addBaggageExists={addBaggageExists}
                                  getOperatingAirlineName={getOperatingAirlineName}
                                />
                              }
                              <div className="row">
                                {window.innerWidth > 600 &&
                                  <div className="col-sm-2 col-xs-2 inner_logo_block">
                                    <div className="inner_logo_div">
                                      <ArrowTooltips
                                        text={getAirlineName(el1.carrierCode)}
                                      >
                                        <img
                                          src={`/assets/img/logos/${el1.carrierCode}.gif`}
                                          className="inner_logo"
                                          alt="inner_logo"
                                        />
                                      </ArrowTooltips>
                                    </div>
                                    <div className="airport theme-search-result-airport-name">
                                      {outwardSegment &&
                                        `${el1.carrierCode} ${el1.flight_number}`}
                                      <p>{getAirlineName(el1.carrierCode)}</p>
                                      <p>{showCapitlized(el1?.cabin)}</p>
                                      {el1.operatingCode !== "" ? (
                                        <>
                                          <br />
                                          <p className="fit-content">
                                            Operated by <br />
                                            {getOperatingAirlineName(
                                              el1.operatingCode
                                            )}
                                          </p>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                }
                                <div className="col-sm-3 col-xs-4 inner_from theme-flight-list-items">
                                  <div className="time">
                                    {moment(el1.departure.dateTime).format(
                                      "HH:mm"
                                    )}
                                  </div>
                                  <div className="location">
                                    <ArrowTooltips
                                      text={getAirportName(
                                        el1.departure.iataCode
                                      )}
                                    >
                                      <span>{`${getAirportName(
                                        el1.departure.iataCode,
                                        "name"
                                      )}(${el1.departure.iataCode})`}</span>
                                    </ArrowTooltips>
                                  </div>
                                  <div className="date">
                                    {moment(el1.departure.dateTime).format(
                                      "ll"
                                    )}
                                  </div>
                                  <TerminalDetails terminal={el1.departure?.terminal} />
                                </div>
                                <div className="col-sm-3 col-xs-4 xs-0 theme-flight-list-items">
                                  <ArrowTooltips text="Flight Duration">
                                    <img
                                      className="inner_arrow"
                                      alt="inner_arrow"
                                      src="/assets/ficon/inner-clock.png"
                                    />
                                  </ArrowTooltips>
                                  <div className="inner_duration">
                                    {el1.duration}
                                  </div>
                                </div>
                                <div className="col-sm-3 col-xs-4 inner_to theme-flight-list-items">
                                  <div className="time">
                                    {moment(el1.arrival.dateTime).format(
                                      "HH:mm"
                                    )}
                                  </div>
                                  <div className="location">
                                    <ArrowTooltips
                                      text={getAirportName(
                                        el1.arrival.iataCode
                                      )}
                                    >
                                      <span>{`${getAirportName(
                                        el1.arrival.iataCode,
                                        "name"
                                      )}(${el1.arrival.iataCode})`}</span>
                                    </ArrowTooltips>
                                  </div>
                                  <div className="date">
                                    {moment(el1.arrival.dateTime).format("ll")}
                                  </div>
                                  <TerminalDetails terminal={el1.arrival?.terminal} />
                                </div>
                                {/* <div className="col-sm-1 hidden-xs">
                                  <div className="inner_icons">
                                    <ArrowTooltips text="Seat">
                                      <img
                                        className="inner_image-icons"
                                        alt="inner_image-icons"
                                        style={{ marginBottom: "5px" }}
                                        src="/assets/ficon/flight-seat.png"
                                      />
                                    </ArrowTooltips>
                                    <ArrowTooltips text="Wi-Fi">
                                      <img
                                        className="inner_image-icons"
                                        alt="inner_image-icons"
                                        style={{ marginBottom: "5px" }}
                                        src="/assets/ficon/wi-fi.png"
                                      />
                                    </ArrowTooltips>
                                    <ArrowTooltips text="Video">
                                      <img
                                        className="inner_image-icons"
                                        alt="inner_image-icons"
                                        style={{ marginBottom: "5px" }}
                                        src="/assets/ficon/video.png"
                                      />
                                    </ArrowTooltips>
                                    <ArrowTooltips text="Power Outlet">
                                      <img
                                        className="inner_image-icons"
                                        alt="inner_image-icons"
                                        style={{ marginBottom: "5px" }}
                                        src="/assets/ficon/electrical.png"
                                      />
                                    </ArrowTooltips>
                                    <ArrowTooltips text="Snacks">
                                      <img
                                        className="inner_image-icons"
                                        alt="inner_image-icons"
                                        src="/assets/ficon/food.png"
                                      />
                                    </ArrowTooltips>
                                  </div>
                                </div> */}
                              </div>
                              {window.innerWidth > 600 && <Baggage bagge={addBaggageExists ? 1 : el1?.bagge} />}
                            </div>
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(RoundFlightItem);
