import React, {useContext, useEffect, useState} from "react";
import BottomStaticCard from "../Popup/BottomStaticCard";
import ArrowTooltips from "../../Shared/Tooltip";
import moment from "moment";
import qs from "qs";
import _ from "lodash";
import FlightService from "../../Service/FlightService";
import FlightLoader from "../../DefaultComponent/FlightLoader";
import {notification} from "../../Shared/NotificationModal";
import LoginContext from "../../Context/Context";

const ThankYou = (props) =>{
    const [airportName, setAirportName] = useState([]);
    const [airlineName, setAirlineName] = useState([]);
    const [queryParam, setqueryParam] = useState([]);
    const [loading, setLoading] = useState("false");
    const [el, setEl] = useState(null);
    const context = useContext(LoginContext);
    const flightServ = new FlightService();
    useEffect(() => {
        const bookObj = qs.parse((props.location.search)?.substring(1));
        setqueryParam(bookObj);
        try {
            setLoading("true");
            flightServ.bookingHistoryDetail(bookObj ? bookObj.bookingId : "").then(async (res) => {
                let airdata = JSON.parse(res?.detail);
                airdata.bookingNo = res.bookingId
                const inwardSegment = _.flatten(airdata?.inwardSegment.map(({arrival, departure}) => [arrival.iataCode, departure.iataCode]));
                const outwardSegment = _.flatten(airdata?.outwardSegment.map(({arrival, departure}) => [arrival.iataCode, departure.iataCode]))
                const uniqIata = _.uniq([...inwardSegment, ...outwardSegment]);
                let result = await flightServ.ListgetAirportCode(uniqIata);
                setEl(airdata);
                setAirportName(result);
                setLoading("false");
                return airdata;
            }).then((res) => {
                const inwardSegment = _.flatten(res?.inwardSegment.map(({carrierCode}) => [carrierCode]));
                const outwardSegment = _.flatten(res?.outwardSegment.map(({carrierCode}) => [carrierCode]));
                const uniqIata = _.uniq([...inwardSegment, ...outwardSegment]);
                flightServ.getAirLineCode(uniqIata).then((ress) => {
                    let n = ress.map((el) => {
                        return {
                            name: el.name,
                            iata: el.iata,
                            minprice: 0,
                        };
                    });
                    setAirlineName(n)
                })
            });
        }catch(e){
            notification("error","Failed to fetch booked flight details. Please try after some time.")
        }
    }, []);
    const getAirlineName = (code) =>{
        const airline = airlineName && airlineName.find(({iata}) => iata === code);
        return airline ? airline.name : ""
    };
    const getAirportName = (code) =>{
        const airport = airportName && airportName.find(({iata}) => iata === code);
        return airport ? airport.name : ""
    };

    return(
        <>
            <FlightLoader show={loading}/>
            <div className="bg-light">
            <div className="thankyou-section">
                <img src={'/assets/ficon/images/flight-thank.png'} alt="thank you" className="thank-you-img" />
                <div className="thank-you-text">{queryParam.status === "failed" ? "Oops. We couldn't book your flights." : "Thank You for booking with Flights Travel"}</div>
            </div>
            <div className="booking-section">
                <div className="Booking-confirmed-card">
                    <div className="col-md-12 _p-0">
                        <div className="heading-text">
                            <p className="booking-confirmed-title">{queryParam.status === "failed" ? "Booking Failed." : "Booking Confirmed"}</p>
                            {/* <p>Status: <a href="#">Check here</a></p> */}
                        </div>
                    </div>
                    <div className="greeting-with-detail">
                        {/*<p>Hi Dear,</p>*/}
                        <p>Your <strong> {`${getAirportName(el && el.from)} to ${getAirportName(el && el.to)} `} </strong>{`${el && el.inwardSegment.length ? "Round Trip" : "One way"} `} flight booking is {queryParam.status === "failed" ? <span>failed. <br/>{`Please contact our customer service at `}
                        <a href={`tel:${context.SettingList.contact_phone}`}>
                            {context.SettingList.contact_phone}
                        </a> 
                        {` for further assistance.`}
                        </span> : <span>confirmed.</span>}</p>
                    </div>
                    <div>
                        {queryParam.status === "failed" ? "" : <span>Your Trip ID is <b>{el && el.bookingNo}</b></span> }
                    </div>
                    <div className="d-flex booking-detail">
                        {el ?
                         <div
                            className="col-md-8 width100 border-right"
                        >
                            <div className="theme-search-results-item-flight-sections">
                                <div className="theme-search-results-item-flight-section flight-section departing-flight-detail">
                                    <h4 className="flight-detail-heading">Departing Flight</h4>
                                    <div className="row row-no-gutter row-eq-height">
                                        <div className="col-xs-2 col-md-2">
                                            <div className="theme-search-results-item-flight-section-airline-logo-wrap">
                                                <ArrowTooltips text={getAirlineName(el.outwardSegment[0].carrierCode)}>
                                                    <img
                                                        className="theme-search-results-item-flight-section-airline-logo"
                                                        src={`/assets/img/logos/${el.outwardSegment[0].carrierCode}.gif`}
                                                        alt="Image Alter"
                                                    />
                                                </ArrowTooltips>
                                            </div>
                                        </div>
                                        <div className="col-xs-10 col-md-10">
                                            {/* <div className="theme-search-results-item-flight-section-item"> */}
                                            <div className="row" style={{margin:"0"}}>
                                                <div className="col-sm-3 col-xs-4" style={{paddingLeft: "0"}}>
                                                    <div className="theme-search-results-item-flight-section-meta flight-right-meta-text">
                                                        <p className="theme-search-results-item-flight-section-meta-time">
                                                            {moment(el.outwardSegment[0].departure.dateTime).format("HH:mm")}
                                                        </p>
                                                        <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                                                            {el.outwardSegment.map((el1, i1) => {
                                                                if (i1 === 0) return moment(el1.departure.dateTime).format("ll");
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="hidden-xs col-sm-3 col-xs-3">
                                                            <div className="theme-search-results-item-flight-section-meta">
                                                              <p className="theme-search-results-item-flight-section-meta-time">
                                                                {el.outwardSegment.map((el1, i) => {
                                                                  if (i == el.outwardSegment.length - 1) {
                                                                    return moment(el1.arrival.dateTime).format("HH:mm");
                                                                  }
                                                                })}
                                                              </p>
                                                              <p className="theme-search-results-item-flight-section-meta-date">
                                                                {el.outwardSegment.map((el1, i1) => {
                                                                  if (i1 === el.outwardSegment.length - 1)
                                                                    return moment(el1.departure.dateTime).format("ll");
                                                                })}
                                                              </p>
                                                            </div>
                                                          </div> */}
                                                <div className="col-sm-5 col-xs-4" style={{padding: "0"}}>
                                                    <div className="theme-search-results-item-flight-section-path flight-search-path-section">
                                                        <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                                                            <p className="fly-time-text-content">
                                                                  <span>
                                                                    {el.outwardStop == 0
                                                                        ? "DIRECT"
                                                                        : el.outwardStop + `${el.outwardStop == 1 ? " Stop" : " Stops"}`}{" "}
                                                                  </span>
                                                                <span className="fly-time-icon-span">| <img src="/assets/ficon/clock.png" alt="clock"/></span>
                                                                {el.totalOutboundDuration}{" "}
                                                            </p>
                                                        </div>

                                                        <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                                                        <div className="theme-search-results-item-flight-section-path-line-start">
                                                            <img src="/assets/ficon/airplane-take-off.png" alt="airplane-take-off" className="airplane-take-off" />

                                                            <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                            <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                                                {el.outwardSegment.map((el1, i) => {
                                                                    if (i === 0) {
                                                                        return airportName.map((el2) => {
                                                                            if (el2.iata == el1.departure.iataCode) {
                                                                                return <ArrowTooltips text={el2.airport_name}><span>{el1.departure.iataCode}</span></ArrowTooltips>
                                                                            }
                                                                        });
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                        {el.outwardStop === 1 && (
                                                            <div className="theme-search-results-item-flight-section-path-line-middle">
                                                                <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                                                <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                                <div
                                                                    style={{ color: "#7A7E82" }}
                                                                    className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                                                >
                                                                    {el.outwardSegment.map((el1, i) => {
                                                                        if (i === 0) {
                                                                            return airportName.map((el2) => {
                                                                                if (el2.iata == el1.arrival.iataCode) {
                                                                                    return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                                }
                                                                            });
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {el.outwardStop === 2 && (
                                                            <>
                                                                <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                                                    <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                                                    <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                                    <div
                                                                        style={{ color: "#7A7E82" }}
                                                                        className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                                                    >
                                                                        {el.outwardSegment.map((el1, i) => {
                                                                            if (i === 0) {
                                                                                return airportName.map((el2) => {
                                                                                    if (el2.iata == el1.arrival.iataCode) {
                                                                                        return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                                    }
                                                                                });
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                                                    <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                                                    <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                                    <div
                                                                        style={{ color: "#7A7E82" }}
                                                                        className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                                                    >
                                                                        {el.outwardSegment.map((el1, i) => {
                                                                            if (i === 1) {
                                                                                return airportName.map((el2) => {
                                                                                    if (el2.iata == el1.arrival.iataCode) {
                                                                                        return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                                    }
                                                                                });
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="theme-search-results-item-flight-section-path-line-end">
                                                        <img src="/assets/ficon/airplane-landing.png" className="airplane-take-off" />
                                                            <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                            <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                                                {" "}
                                                                {el.outwardSegment.map((el1, i) => {
                                                                    if (i === el.outwardSegment.length - 1) {
                                                                        return airportName.map((el2) => {
                                                                            if (el2.iata == el1.arrival.iataCode) {
                                                                                return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                            }
                                                                        });
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-xs-4" style={{paddingRight: "0"}}>
                                                    <div className="theme-search-results-item-flight-section-meta flight-left-meta-text">
                                                        {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                                                        <p className="theme-search-results-item-flight-section-meta-time">
                                                            {el.outwardSegment.map((el1, i) => {
                                                                if (i == el.outwardSegment.length - 1) {
                                                                    return moment(el1.arrival.dateTime).format("HH:mm");
                                                                }
                                                            })}
                                                        </p>
                                                        <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                                                            {el.outwardSegment.map((el1, i1) => {
                                                                if (i1 === el.outwardSegment.length - 1)
                                                                    return moment(el1.arrival.dateTime).format("ll");
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                {el && el.inwardSegment && el.inwardSegment.length ?
                                <div className="theme-search-results-item-flight-section flight-section return-flight-detail">
                                    <h4 className="flight-detail-heading">Return Flight</h4>
                                    <div className="row row-no-gutter row-eq-height">
                                        <div className="col-xs-2 col-md-2">
                                            <div className="theme-search-results-item-flight-section-airline-logo-wrap">
                                                <ArrowTooltips text={getAirlineName(el.inwardSegment[0].carrierCode)}>
                                                    <img
                                                        className="theme-search-results-item-flight-section-airline-logo"
                                                        src={`/assets/img/logos/${el.inwardSegment[0].carrierCode}.gif`}
                                                        alt="Image Altern"
                                                    />
                                                </ArrowTooltips>
                                            </div>
                                        </div>
                                        <div className="col-xs-10 col-md-10">
                                            {/* <div className="theme-search-results-item-flight-section-item"> */}
                                            <div className="row" style={{margin:"0"}}>
                                                <div className="col-sm-3 col-xs-4" style={{paddingLeft: "0"}}>
                                                    <div className="theme-search-results-item-flight-section-meta flight-right-meta-text">
                                                        {/* <div className="dip-arri  ">DEPARTURE TIME</div> */}
                                                        <p className="theme-search-results-item-flight-section-meta-time">
                                                            {el.inwardSegment.map((el1, i) => {
                                                                if (i == 0) {
                                                                    return moment(el1.departure.dateTime).format("HH:mm");
                                                                }
                                                            })}
                                                        </p>
                                                        <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                                                            {el.inwardSegment.map((el1, i) => {
                                                                if (i == 0) {
                                                                    return moment(el1.departure.dateTime).format("ll");
                                                                }
                                                            })}
                                                        </p>

                                                    </div>
                                                </div>
                                                {/* <div className="visible-xs col-sm-3 col-xs-6">
                                                            <div className="theme-search-results-item-flight-section-meta">
                                                              {/* <div className="dip-arri  ">ARRIVAL TIME</div> *
                                                              <p className="theme-search-results-item-flight-section-meta-time">
                                                                {el.inwardSegment.map((el1, i) => {
                                                                  if (i == el.inwardSegment.length - 1) {
                                                                    return moment(el1.arrival.dateTime).format("HH:mm");
                                                                  }
                                                                })}
                                                              </p>
                                                              <p className="theme-search-results-item-flight-section-meta-date">
                                                                {el.inwardSegment.map((el1, i1) => {
                                                                  if (i1 === el.inwardSegment.length - 1)
                                                                    return moment(el1.arrival.dateTime).format("ll");
                                                                })}
                                                              </p>
                                                            </div>
                                                          </div> */}
                                                <div className="col-sm-5 col-xs-4" style={{padding: "0"}}>
                                                    <div className="theme-search-results-item-flight-section-path flight-search-path-section">
                                                        <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                                                            <p className="fly-time-text-content">
                                                                  <span>
                                                                    {el.inwardStop == 0
                                                                        ? "DIRECT"
                                                                        : el.inwardStop + `${el.inwardStop == 1 ? " Stop" : " Stops"}`}{" "}
                                                                  </span>
                                                                <span className="fly-time-icon-span">| <img src="/assets/ficon/clock.png" alt="clock"/></span>
                                                                {el.totalInboundDuration}
                                                            </p>
                                                        </div>
                                                        <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                                                        <div className="theme-search-results-item-flight-section-path-line-start">
                                                            <img src="/assets/ficon/airplane-take-off.png" alt="airplane-take-off" className="airplane-take-off"/>
                                                            <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                            <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                                                {el.inwardSegment.map((el1, i) => {
                                                                    if (i === 0) {
                                                                        return airportName.map((el2) => {
                                                                            if (el2.iata == el1.departure.iataCode) {
                                                                                return <ArrowTooltips text={el2.airport_name}><span>{el1.departure.iataCode}</span></ArrowTooltips>
                                                                            }
                                                                        });
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                        {el.inwardStop === 1 && (
                                                            <div className="theme-search-results-item-flight-section-path-line-middle">
                                                                <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                                                <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                                <div
                                                                    style={{ color: "#7A7E82" }}
                                                                    className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                                                >
                                                                    {el.inwardSegment.map((el1, i) => {
                                                                        if (i === 0) {
                                                                            return airportName.map((el2) => {
                                                                                if (el2.iata == el1.arrival.iataCode) {
                                                                                    return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                                }
                                                                            });
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {el.inwardStop === 2 && (
                                                            <>
                                                                <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                                                    <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                                                    <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                                    <div
                                                                        style={{ color: "#7A7E82" }}
                                                                        className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                                                    >
                                                                        {el.inwardSegment.map((el1, i) => {
                                                                            if (i === 0) {
                                                                                return airportName.map((el2) => {
                                                                                    if (el2.iata == el1.arrival.iataCode) {
                                                                                        return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                                    }
                                                                                });
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                                                    <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                                                    <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                                                    <div
                                                                        style={{ color: "#7A7E82" }}
                                                                        className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                                                    >
                                                                        {el.inwardSegment.map((el1, i) => {
                                                                            if (i === 1) {
                                                                                return airportName.map((el2) => {
                                                                                    if (el2.iata == el1.arrival.iataCode) {
                                                                                        return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                                    }
                                                                                });
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="theme-search-results-item-flight-section-path-line-end">
                                                            <img src="/assets/ficon/airplane-landing.png" alt="airplane-landing" className="airplane-take-off"/>
                                                            <div className="theme-search-results-item-flight-section-path-line-dot dot-line-mob"></div>
                                                            <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text">
                                                                {el.inwardSegment.map((el1, i) => {
                                                                    if (i === el.inwardSegment.length - 1) {
                                                                        return airportName.map((el2) => {
                                                                            if (el2.iata == el1.arrival.iataCode) {
                                                                                return <ArrowTooltips text={el2.airport_name}><span>{el1.arrival.iataCode}</span></ArrowTooltips>
                                                                            }
                                                                        });
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-xs-4" style={{paddingRight: "0"}}>
                                                    <div className="theme-search-results-item-flight-section-meta flight-left-meta-text">
                                                        {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                                                        <p className="theme-search-results-item-flight-section-meta-time">
                                                            {el.inwardSegment.map((el1, i) => {
                                                                if (i == el.inwardSegment.length - 1) {
                                                                    return moment(el1.arrival.dateTime).format("HH:mm");
                                                                }
                                                            })}
                                                        </p>
                                                        <p className="theme-search-results-item-flight-section-meta-date meta-date-text">
                                                            {el.inwardSegment.map((el1, i1) => {
                                                                if (i1 === el.inwardSegment.length - 1)
                                                                    return moment(el1.arrival.dateTime).format("ll");
                                                            })}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </div> : null}
                        <div className="col-md-4">
                            <div className="travellers-detail-section">
                                <div className="traveller-title">
                                    <span><strong>Travellers</strong></span>
                                    <span><strong> {el
                                                    ? parseInt(el.bookingInfo.Adults) +
                                                    (el.bookingInfo.Children ? parseInt(el.bookingInfo.Children)  : 0 )+
                                                    (el.bookingInfo.Infants ? parseInt(el.bookingInfo.Infants) : 0 ) 
                                                    : 0}</strong></span>
                                </div>
                                <div className="traveller-content">
                                    <div className="traveller-inner-content">
                                        <span>Adult</span>
                                        <span>{el ? parseInt(el.bookingInfo.Adults) : 0}</span>
                                    </div>
                                    {el && parseInt(el.bookingInfo.Children) ?
                                    <div className="traveller-inner-content">
                                        <span>Child</span>
                                        <span>{el ? parseInt(el.bookingInfo.Children) : 0}</span>
                                    </div> : null}
                                    {el && parseInt(el.bookingInfo.Infants) ?
                                    <div className="traveller-inner-content">
                                        <span>Infants</span>
                                        <span>{el ? parseInt(el.bookingInfo.Infants) : 0}</span>
                                    </div> : null}
                                </div>
                            </div>
                            <div className="payment-detail-section">
                                <div className="payment-title">
                                    <span><strong>Payment</strong></span>
                                </div>
                                <div className="payment-content">
                                    <div><p>£{el && el.totalFare && Number.parseFloat(el.totalFare.amount).toFixed(2)}</p></div>
                                    <div><span>Total price including Fees and Taxes</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ThankYou;

