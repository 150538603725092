import { useEffect } from "react";

import { TIMINGS } from "../constants";
import { isSameObj } from "../helper";
import usePrevious from "./usePrevious";

const useBoundTimeSlider = ({ time, setFilterSelected, onFiltersChange }) => {
  const outBoundTimeSlider = () => {
    var my_from = TIMINGS.indexOf(0);
    var my_to = TIMINGS.indexOf(23);
    window.$("#OUTBOUND-slider").ionRangeSlider({
      type: "double",
      grid: true,
      from: my_from,
      to: my_to,
      values: TIMINGS,

      onFinish: (e) => {
        const { from, to } = e;
        setFilterSelected((prevState) => ({
          ...prevState,
          time: {
            ...prevState.time,
            outbound: { max: to || prevState.time.outbound.max, min: from },
          },
        }));
      },
    });

    window.$("#price-slider-mob").ionRangeSlider({
      type: "double",
      prefix: "£",
    });
    var low = window.$("#price-slider").data().from;
    var high = window.$("#price-slider").data().to;
    var slider = window.$("#OUTBOUND-slider").data("ionRangeSlider");
    slider.update({
      min: TIMINGS[0],
      max: TIMINGS[24],
      from: time.outbound.min,
      to: time.outbound.max,
    });
  };

  const inBoundTimeSlider = () => {
    var my_from = TIMINGS[time.inbound.min];
    var my_to = TIMINGS[time.inbound.max];
    window.$("#INBOUND-slider").ionRangeSlider({
      type: "double",
      grid: true,
      min: TIMINGS[0],
      max: TIMINGS[24],
      from: my_from,
      to: my_to,
      values: TIMINGS,
      onFinish: (e) => {
        const { from, to } = e;
        setFilterSelected((prevState) => ({
          ...prevState,
          time: {
            ...prevState.time,
            inbound: { max: to || prevState.time.inbound.max, min: from },
          },
        }));
      },
    });
    var slider = window.$("#INBOUND-slider").data("ionRangeSlider");
    slider.update({
      min: TIMINGS[0],
      max: TIMINGS[24],
      from: time.inbound.min,
      to: time.inbound.max,
    });
  };

  const oldTime = usePrevious(time);
  useEffect(() => {
    if (!isSameObj(oldTime, time) && time?.inbound) {
      var inSlider = window.$("#INBOUND-slider").data("ionRangeSlider");
      if (inSlider)
        inSlider.update({
          min: TIMINGS[0],
          max: TIMINGS[24],
          from: time?.inbound.min,
          to: time?.inbound.max, 
        });
      var outSlider = window.$("#OUTBOUND-slider").data("ionRangeSlider");
      if (outSlider) {
        outSlider.update({
          min: TIMINGS[0],
          max: TIMINGS[24],
          from: time?.outbound.min,
          to: time?.outbound.max,
        });
      }
      onFiltersChange();
    }
  }, [time]);

  useEffect(() => {
    outBoundTimeSlider();
    inBoundTimeSlider();
  }, []);
};

export default useBoundTimeSlider;
