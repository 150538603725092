import moment from "moment";
import qs from "qs";
import React, { useContext, useState } from "react";
import Delayed from "react-delayed";
import { Link, withRouter } from "react-router-dom";
import Context from "../../Context/Context";
import ArrowTooltips from "../../Shared/Tooltip";
import PhoneOnlyDealPopup from "../Popup/PhoneOnlyDealPopup";
import StaticPopup from "../Popup/StaticPopup";
import { getAirlineName as stripAirlineName } from "./../../Helper/helper";
import { CABIN_OPTIONS } from "./New/constants";
import Baggage from "./New/Components/Baggage";
import { showCapitlized } from "./New/helper";
import AirportDetails from "./New/Components/AirportDetails";
import TerminalDetails from "./New/Components/TerminalDetails";

const SingleTrip = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const location = qs.parse(props.location?.search?.substring(1));
  const currentSearchParams = props.location.search && new URLSearchParams(props.location.search);
  const addBaggageExists = currentSearchParams?.has('addBaggage');
  //let location = props.location?.search?.split("&");
  let utmCampaign = location?.utm_campaign;
  let BookedContext = useContext(Context);
  const getAirlineName = (code) => {
    const airline =
      props.airlineName && props.airlineName.find(({ iata }) => iata === code);
    return airline ? airline.name : "";
  };

  const checkAdultFare = (price) => {
    if (price) {
      return price.AdultFares && price.AdultFares.perAdultFare
        ? price.AdultFares.perAdultFare
        : price.totalFare && price.totalFare.amount;
    }

    return 0;
  };

  const getOperatingAirlineName = (code) => {
    if (code === "AA") {
      return "American Airlines";
    } else if (code === "BA") {
      return "British Airways";
    } else if (code === "UA") {
      return "United Airlines";
    } else {
      return code;
    }
  };

  return (
    <>
      {openPopup ? (
        <PhoneOnlyDealPopup
          title={stripAirlineName(BookedContext.searchReasult.to)}
          contactNo={BookedContext.SettingList.contact_phone}
          handleClose={() => setOpenPopup(false)}
        />
      ) : null}
      <div id="flight-div" className="theme-search-results">
        {props.flightLists.data &&
          props.flightLists.data.map((el, i) => {
            let totalLabel;
            if (el.AdultFares.perAdultFare) {
              totalLabel = "Per Person";
            } else {
              totalLabel = "Total Fare";
            }
            //let path = props?.match?.path.includes("/flightdetails");
            return (
              <Delayed mounted={true} mountAfter={500} unmountAfter={500}>
                <>
                  <div
                    key={el.id}
                    className={`
                             theme-search-results-item _mb-10 
                              theme-search-results-item-rounded 
                             theme-search-results-item ${el.isOffer ? "popup-card phone-deal-card" : ""
                      }`}
                    id={`flight-div-id`}
                  >
                    {el.isOffer ? (
                      <>
                        <div className="ribbon ribbon-top-right">
                          <span>Bargain Deal</span>
                        </div>
                        <div className="card-header phone-deal-card-header">
                          This is Special Phone Only Deal
                        </div>
                      </>
                    ) : null}
                    <div className="theme-search-results-item-preview">
                      <div className="row" data-gutter="20">
                        <div
                          className="col-md-10 width100"
                          role="button"
                          data-toggle="collapse"
                          data-target={`#searchResultsItem-${i + 1}`}
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <div className="theme-search-results-item-flight-sections one-way-card-padding">
                            <div className="theme-search-results-item-flight-section flight-section">
                              <div className="row row-no-gutter row-eq-height">
                                <div className="col-xs-2 col-md-2">
                                  <div
                                    className="theme-search-results-item-flight-section-airline-logo-wrap"
                                    style={{ textAlign: "center" }}
                                  >
                                    <ArrowTooltips
                                      text={getAirlineName(
                                        el.outwardSegment[0].carrierCode
                                      )}
                                    >
                                      <>
                                        <img
                                          className="theme-search-results-item-flight-section-airline-logo"
                                          src={`/assets/img/logos/${el.outwardSegment[0].carrierCode}.gif`}
                                          alt="Image Alter"
                                        />

                                        <p className="airline-name-para">
                                          <p>
                                            {getAirlineName(
                                              el.outwardSegment[0].carrierCode
                                            )}
                                          </p>
                                          {el?.outwardSegment?.[0]?.cabin ? <p>{showCapitlized(el?.outwardSegment?.[0]?.cabin)}</p> : ""}
                                          <p style={{ color: "white" }}>
                                            {el?.outwardSegment[0]?.class}
                                          </p>
                                          {el.outwardSegment[0]
                                            .operatingCode !== "" ? (
                                            <>
                                              <p className="airline-name-para fit-content">
                                                <span>
                                                  Operated by <br />
                                                  {getOperatingAirlineName(
                                                    el.outwardSegment[0]
                                                      .operatingCode
                                                  )}
                                                </span>
                                              </p>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </p>
                                      </>
                                    </ArrowTooltips>
                                  </div>
                                </div>
                                <div className="col-xs-10 col-md-10 theme-flight-list-content">
                                  {/* <div className="theme-search-results-item-flight-section-item"> */}
                                  <div className="row" style={{ margin: "0" }}>
                                    <div
                                      className="col-sm-3 col-xs-3 pr-0-mobile-only"
                                      style={{ paddingLeft: "0" }}
                                    >
                                      <div className="theme-search-results-item-flight-section-meta flight-right-meta-text mr-0-mobile-only">
                                        <p className="theme-search-results-item-flight-section-meta-time">
                                          {moment(
                                            el.outwardSegment[0].departure
                                              .dateTime
                                          ).format("HH:mm")}
                                        </p>
                                        <p className="hidden-xs theme-search-results-item-flight-section-meta-date meta-date-text">
                                          {el.outwardSegment.map((el1, i1) => {
                                            if (i1 === 0)
                                              return moment(
                                                el1.departure.dateTime
                                              ).format("ll");
                                          })}
                                        </p>
                                        <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date meta-date-text">
                                          {el.outwardSegment.map((el1, i1) => {
                                            let dateWithoutYr = moment(
                                              el1.departure.dateTime
                                            )
                                              .format("ll")
                                              .split(",")[0];
                                            if (i1 === 0) return dateWithoutYr;
                                          })}
                                        </p>

                                        <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date flight-section-airport-text">
                                          {el.outwardSegment.map((el1, i) => {
                                            if (i === 0) {
                                              return props.airportName.map(
                                                (el2) => {
                                                  if (
                                                    el2.iata ===
                                                    el1.departure.iataCode
                                                  ) {
                                                    return (
                                                      <ArrowTooltips
                                                        text={
                                                          el2.airport_name
                                                        }
                                                      >
                                                        <span>
                                                          {
                                                            el1.departure
                                                              .iataCode
                                                          }
                                                        </span>
                                                      </ArrowTooltips>
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                    {/*                                   <div className="visible-xs col-sm-3 col-xs-6">
                                    <div className="theme-search-results-item-flight-section-meta">
                                      <div className="dip-arri  ">ARRIVAL TIME</div>
                                      <p className="theme-search-results-item-flight-section-meta-time">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i == el.outwardSegment.length - 1) {
                                            return moment(el1.arrival.dateTime).format("HH:mm");
                                          }
                                        })}
                                      </p>
                                      <p className="theme-search-results-item-flight-section-meta-date">
                                        {el.outwardSegment.map((el1, i1) => {
                                          if (i1 === el.outwardSegment.length - 1)
                                            return moment(el1.departure.dateTime).format("ll");
                                        })}
                                      </p>
                                    </div>
                                  </div> */}
                                    <div
                                      className="col-sm-5 col-xs-6"
                                      style={{ padding: "0" }}
                                    >
                                      <div className="theme-search-results-item-flight-section-path">
                                        <div className="theme-search-results-item-flight-section-path-fly-time fly-time-text">
                                          <p className="fly-time-text-content">
                                            <span>
                                              {el.outwardStop === 0
                                                ? "DIRECT"
                                                : el.outwardStop +
                                                `${el.outwardStop === 1
                                                  ? " Stop"
                                                  : " Stops"
                                                }`}{" "}
                                            </span>
                                            <span className="fly-time-icon-span">
                                              |{" "}
                                              <img
                                                alt="clock"
                                                src="/assets/ficon/clock.png"
                                              />
                                            </span>
                                            {el.totalOutboundDuration}{" "}
                                          </p>
                                        </div>

                                        <div className="theme-search-results-item-flight-section-path-line dot-line-mob"></div>
                                        <div className="theme-search-results-item-flight-section-path-line-start">
                                          <img
                                            src="/assets/ficon/airplane-take-off.png"
                                            className="airplane-take-off-webonly"
                                            alt="airplane-take-off"
                                          />

                                          <div className="theme-search-results-item-flight-section-path-line-dot hidden-xs"></div>
                                          <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text hidden-xs">
                                            {el.outwardSegment.map((el1, i) => {
                                              if (i === 0) {
                                                return props.airportName.map(
                                                  (el2) => {
                                                    if (
                                                      el2.iata ===
                                                      el1.departure.iataCode
                                                    ) {
                                                      return (
                                                        <ArrowTooltips
                                                          text={
                                                            el2.airport_name
                                                          }
                                                        >
                                                          <span>
                                                            {
                                                              el1.departure
                                                                .iataCode
                                                            }
                                                          </span>
                                                        </ArrowTooltips>
                                                      );
                                                    }
                                                  }
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                        {el.outwardStop === 1 && (
                                          <div className="theme-search-results-item-flight-section-path-line-middle">
                                            <i className="fa fa-plane theme-search-results-item-flight-section-path-icon middle-path-icon"></i>
                                            <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                            <div
                                              style={{ color: "#7A7E82" }}
                                              className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                            >
                                              {el.outwardSegment.map(
                                                (el1, i) => {
                                                  if (i === 0) {
                                                    return props.airportName.map(
                                                      (el2, index) => {
                                                        if (
                                                          el2.iata ===
                                                          el1.arrival.iataCode
                                                        ) {
                                                          return (
                                                            <ArrowTooltips
                                                              text={
                                                                el2.airport_name
                                                              }
                                                              key={index}
                                                            >
                                                              <span>
                                                                {
                                                                  el1.arrival
                                                                    .iataCode
                                                                }
                                                              </span>
                                                            </ArrowTooltips>
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                          </div>
                                        )}

                                        {el.outwardStop === 2 && (
                                          <>
                                            <div className="theme-search-results-item-flight-section-path-line-middle-1">
                                              <i className="fa fa-plane theme-search-results-item-flight-section-path-icon"></i>
                                              <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                              <div
                                                style={{ color: "#7A7E82" }}
                                                className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                              >
                                                {el.outwardSegment.map(
                                                  (el1, i) => {
                                                    if (i === 0) {
                                                      return props.airportName.map(
                                                        (el2, index) => {
                                                          if (
                                                            el2.iata ===
                                                            el1.arrival.iataCode
                                                          ) {
                                                            return (
                                                              <ArrowTooltips
                                                                text={
                                                                  el2.airport_name
                                                                }
                                                                key={index}
                                                              >
                                                                <span>
                                                                  {
                                                                    el1.arrival
                                                                      .iataCode
                                                                  }
                                                                </span>
                                                              </ArrowTooltips>
                                                            );
                                                          }
                                                        }
                                                      );
                                                    }
                                                  }
                                                )}
                                              </div>
                                            </div>
                                            <div className="theme-search-results-item-flight-section-path-line-middle-2">
                                              <i className="fa fa-plane theme-search-results-item-flight-section-path-icon"></i>
                                              <div className="theme-search-results-item-flight-section-path-line-dot"></div>
                                              <div
                                                style={{ color: "#7A7E82" }}
                                                className="theme-search-results-item-flight-section-path-line-title flight-search-code-text"
                                              >
                                                {el.outwardSegment.map(
                                                  (el1, i) => {
                                                    if (i === 1) {
                                                      return props.airportName.map(
                                                        (el2) => {
                                                          if (
                                                            el2.iata ===
                                                            el1.arrival.iataCode
                                                          ) {
                                                            return (
                                                              <ArrowTooltips
                                                                key={i}
                                                                text={
                                                                  el2.airport_name
                                                                }
                                                              >
                                                                <span>
                                                                  {
                                                                    el1.arrival
                                                                      .iataCode
                                                                  }
                                                                </span>
                                                              </ArrowTooltips>
                                                            );
                                                          }
                                                        }
                                                      );
                                                    }
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        <div className="theme-search-results-item-flight-section-path-line-end">
                                          <img
                                            src="/assets/ficon/airplane-landing.png"
                                            className="airplane-take-off-webonly"
                                            alt="airplane-take-off"
                                          />
                                          <div className="theme-search-results-item-flight-section-path-line-dot hidden-xs"></div>
                                          <div className="theme-search-results-item-flight-section-path-line-title flight-search-code-text hidden-xs">
                                            {" "}
                                            {el.outwardSegment.map((el1, i) => {
                                              if (
                                                i ===
                                                el.outwardSegment.length - 1
                                              ) {
                                                return props.airportName.map(
                                                  (el2) => {
                                                    if (
                                                      el2.iata ===
                                                      el1.arrival.iataCode
                                                    ) {
                                                      return (
                                                        <ArrowTooltips
                                                          key={i}
                                                          text={
                                                            el2.airport_name
                                                          }
                                                        >
                                                          <span>
                                                            {
                                                              el1.arrival
                                                                .iataCode
                                                            }
                                                          </span>
                                                        </ArrowTooltips>
                                                      );
                                                    }
                                                  }
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="col-sm-3 col-xs-3 pl-0-mobile-only"
                                      style={{ paddingRight: "0" }}
                                    >
                                      <div className="theme-search-results-item-flight-section-meta flight-left-meta-text ml-0-mobile-only">
                                        {/* <div className="dip-arri  ">ARRIVAL TIME</div> */}
                                        <p className="theme-search-results-item-flight-section-meta-time">
                                          {el.outwardSegment.map((el1, i) => {
                                            if (
                                              i ===
                                              el.outwardSegment.length - 1
                                            ) {
                                              return moment(
                                                el1.arrival.dateTime
                                              ).format("HH:mm");
                                            }
                                          })}

                                          <span>
                                            {/* {el.outwardSegment.map((el1, i) => {
                                          if (i == el.outwardSegment.length - 1) {
                                            return moment(el1.arrival.dateTime).format("a");
                                          }
                                        })} */}
                                          </span>
                                        </p>
                                        {/* <p className="theme-search-results-item-flight-section-meta-city">
                                      {el.outwardSegment.map((el1, i) => {
                                        if (i === el.outwardSegment.length - 1) return el1.arrival.iataCode;
                                      })}
                                    </p> */}
                                        <p className="hidden-xs theme-search-results-item-flight-section-meta-date meta-date-text">
                                          {el.outwardSegment.map((el1, i1) => {
                                            if (
                                              i1 ===
                                              el.outwardSegment.length - 1
                                            )
                                              return moment(
                                                el1.arrival.dateTime
                                              ).format("ll");
                                          })}
                                        </p>
                                        <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date meta-date-text">
                                          {el.outwardSegment.map((el1, i1) => {
                                            let dateWithoutYr = moment(
                                              el1.departure.dateTime
                                            )
                                              .format("ll")
                                              .split(",")[0];
                                            if (
                                              i1 ===
                                              el.outwardSegment.length - 1
                                            )
                                              return dateWithoutYr;
                                          })}
                                        </p>
                                        <p className="hidden-sm hidden-md hidden-lg theme-search-results-item-flight-section-meta-date flight-section-airport-text">
                                          {el.outwardSegment.map((el1, i) => {
                                            if (
                                              i ===
                                              el.outwardSegment.length - 1
                                            ) {
                                              return props.airportName.map(
                                                (el2) => {
                                                  if (
                                                    el2.iata ===
                                                    el1.arrival.iataCode
                                                  ) {
                                                    return (
                                                      <ArrowTooltips
                                                        key={i}
                                                        text={
                                                          el2.airport_name
                                                        }
                                                      >
                                                        <span>
                                                          {
                                                            el1.arrival
                                                              .iataCode
                                                          }
                                                        </span>
                                                      </ArrowTooltips>
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="xs-checked-bagge col-sm-1 border-left">
                                      <ArrowTooltips
                                        text={
                                          addBaggageExists ? "1 checked baggage allowance" : 
                                          el.outwardSegment[0].bagge === "0" ? "Hand Luggage Only" : `${el.outwardSegment[0].bagge} checked baggage allowance `
                                        }
                                      >
                                        <img
                                          className="_mb-10 image-icons web-only"
                                          alt="luggage"
                                          src={`/assets/ficon/${el.outwardSegment[0].bagge === 0
                                            ? addBaggageExists ? "luggage-blue" : "luggage-red"
                                            : "luggage-blue"
                                            }.png`}
                                        />
                                      </ArrowTooltips>
                                      <ArrowTooltips text="Atol Protected">
                                        <img
                                          className="_mb-10 image-icons web-only"
                                          alt="flight seat"
                                          src="/assets/ficon/atol_protection.png"
                                          id="flight-seat"
                                        />
                                      </ArrowTooltips>
                                    </div>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 width100 border-left">
                          <div className="theme-search-results-item-book flight-book">
                            <div className="row mobile-only">
                              <div className="col-xs-7 pl-5-mobile-only">
                                <p className="book-price price-left">{totalLabel}</p>
                                <div className="theme-search-results-item-price price-left">
                                  <p className="theme-search-results-item-price-tag">
                                    £{parseFloat(checkAdultFare(el)).toFixed(2)}
                                  </p>
                                  <p className="theme-search-results-item-price-sign">
                                    Including All Taxes
                                  </p>
                                </div>
                              </div>
                              <div className="col-xs-5 pr-5-mobile-only">
                                {props.flightLists.data.length > 1 ||
                                  window.location.pathname === "/search" ? (
                                  <>
                                    {el.isOffer ||
                                      BookedContext.campaignDetails.is_callNow ? (
                                      <a
                                        className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                                        onClick={() => setOpenPopup(true)}
                                      >
                                        Call Now
                                      </a>
                                    ) : (
                                      <Link
                                        className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn"
                                        onClick={() => {
                                          BookedContext.isFromFlightDetails(true)
                                          BookedContext.handleBookedFlight({
                                            data: [el],
                                            iataAirlineCodes:
                                              props.flightLists.iataAirlineCodes,
                                            airlineName: props.airlineName,
                                            airportName: props.airportName,
                                          });
                                        }}
                                        onContextMenu={(e) => e.preventDefault()}
                                        to={{
                                          pathname: "/flightDetails",
                                          search: `?${qs.stringify({
                                            getFlightInfo: {
                                              itemref: el.itemref,
                                              outavail: el.outavail,
                                              inbavail: el.inbavail,
                                              session_id: el.session_id,
                                              searchinfo: {
                                                from: el.from,
                                                to: el.to,
                                                trip: "SingleTrip",
                                                clientRef: el.clientRef,
                                                utm_campaign: utmCampaign
                                                  ? utmCampaign
                                                  : "localSearch",
                                                cabin: el.cabin || CABIN_OPTIONS[0].value,
                                              },
                                            },
                                          })}`,
                                        }}
                                      >
                                        SELECT
                                      </Link>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="web-only">

                              <p className="book-price price-left">{totalLabel}</p>
                              <div className="theme-search-results-item-price price-left">
                                <p className="theme-search-results-item-price-tag">
                                  £{parseFloat(checkAdultFare(el)).toFixed(2)}
                                </p>
                                <p className="theme-search-results-item-price-sign">
                                  Including All Taxes
                                </p>
                              </div>


                              {props.flightLists.data.length > 1 ||
                                window.location.pathname === "/search" ? (
                                <>
                                  {el.isOffer ||
                                    BookedContext.campaignDetails.is_callNow ? (
                                    <a
                                      className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn call-now-button"
                                      onClick={() => setOpenPopup(true)}
                                    >
                                      Call Now
                                    </a>
                                  ) : (
                                    <Link
                                      className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn"
                                      onClick={() => {
                                        BookedContext.isFromFlightDetails(true)
                                        BookedContext.handleBookedFlight({
                                          data: [el],
                                          iataAirlineCodes:
                                            props.flightLists.iataAirlineCodes,
                                          airlineName: props.airlineName,
                                          airportName: props.airportName,
                                        });
                                      }}
                                      onContextMenu={(e) => e.preventDefault()}
                                      to={{
                                        pathname: "/flightDetails",
                                        search: `?${qs.stringify({
                                          getFlightInfo: {
                                            itemref: el.itemref,
                                            outavail: el.outavail,
                                            inbavail: el.inbavail,
                                            session_id: el.session_id,
                                            searchinfo: {
                                              from: el.from,
                                              to: el.to,
                                              trip: "SingleTrip",
                                              clientRef: el.clientRef,
                                              utm_campaign: utmCampaign
                                                ? utmCampaign
                                                : "localSearch",
                                              cabin: el.cabin || CABIN_OPTIONS[0].value,
                                            },
                                          },
                                        })}`,
                                      }}
                                    >
                                      SELECT
                                    </Link>
                                  )}
                                </>
                              ) : null}

                            </div>
                            {el.isOffer ? (
                              <div className="left_seat">
                                {/*<img
                                  src={"/assets/ficon/limited_availability.png"}
                                />*/}
                                Limited Availibility
                              </div>
                            ) : (
                              el?.seats && (
                                <div className="left_seat">
                                  {el.seats} Seats Left
                                </div>
                              )
                            )}

                            <div className="iter iter-round itenerary-text">
                              {/* <img width="110px" src="/assets/ficon/iter.png" /> */}
                              <a
                                data-toggle="collapse"
                                data-target={`#searchResultsItem-${i + 1}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                role="button"
                              >
                                Flight Details
                              </a>
                            </div>
                            {props.fareRule?.length && (
                              <div
                                className="iter iter-round itenerary-text cursor"
                                data-toggle="collapse"
                                data-target={`#rule-collapse-${i + 1}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <a>Show Fare Rules</a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id={`rule-collapse-${i + 1}`}
                      className="collapse fare-rules-collapse"
                      aria-expanded="true"
                    >
                      <div className="theme-search-results-item-extend-inner extend-inner-padding">
                        {props.fareRule?.length
                          ? props.fareRule.map((rule, index) => (
                            <span key={index}>
                              {rule}
                              <br />
                            </span>
                          ))
                          : ""}
                      </div>
                    </div>
                    <div
                      className="theme-search-results-item-collapse collapsing"
                      id={`searchResultsItem-${i + 1}`}
                      aria-expanded="true"
                      style={
                        props.flightLists.data.length >= 1 &&
                          props.match.url != "/flightdetails"
                          ? { height: "0px" }
                          : { height: "auto" }
                      }
                    >
                      <div className="theme-search-results-item-extend">
                        {props.flightLists.data.length > 1 && (
                          <a
                            className="theme-search-results-item-extend-close"
                            href={`#searchResultsItem-${i + 1}`}
                            role="button"
                            data-toggle="collapse"
                            aria-expanded="true"
                            aria-controls={`searchResultsItem-${i + 1}`}
                          >
                            ✕
                          </a>
                        )}
                        <div className="theme-search-results-item-extend-inner extend-inner-padding">
                          <div className="theme-search-results-item-flight-detail-items">
                            <div className="theme-search-results-item-flight-details">
                              <div className="row">
                                <div className="col-md-11 inner_outbound width100">
                                  <img
                                    src={`/assets/ficon/outbound.png`}
                                    alt="outbound"
                                  />
                                  <span className="inner-outbound-text">
                                    Departure
                                  </span>{" "}
                                  [{" "}
                                  <strong>
                                    {props.airportName.map((el2, index) => {
                                      if (
                                        el2.iata ===
                                        el.outwardSegment[
                                          el.outwardSegment.length - 1
                                        ].departure.iataCode
                                      ) {
                                        return (
                                          <ArrowTooltips
                                            key={index}
                                            text={el2.airport_name}
                                          >
                                            <span>{`${el2.name}`}</span>
                                          </ArrowTooltips>
                                        );
                                      }
                                    })}
                                  </strong>{" "}
                                  to{" "}
                                  <strong>
                                    {props.airportName.map((el2, index) => {
                                      if (
                                        el2.iata ===
                                        el.outwardSegment[
                                          el.outwardSegment.length - 1
                                        ].arrival.iataCode
                                      ) {
                                        return (
                                          <ArrowTooltips
                                            key={index}
                                            text={el2.airport_name}
                                          >
                                            <span>{`${el2.name}`}</span>
                                          </ArrowTooltips>
                                        );
                                      }
                                    })}
                                  </strong>
                                </div>
                                <div className="col-md-11 inner_detail width100">
                                  {window.innerWidth <= 600 &&
                                    <AirportDetails
                                      airLineName={el.outwardSegment[0].carrierCode}
                                      gifCode={el.outwardSegment[0].carrierCode}
                                      carrierCode={el.outwardSegment[0].carrierCode}
                                      flightNumber={el.outwardSegment[0].flight_number}
                                      operatingCode={el.outwardSegment[0].operatingCode}
                                      requiredWard={el.outwardSegment}
                                      cabin={el.outwardSegment[0].cabin}
                                      bagge={el.outwardSegment[0].bagge}
                                      addBaggageExists={addBaggageExists}
                                      getOperatingAirlineName={getOperatingAirlineName}
                                    />
                                  }
                                  <div className="row">
                                    {window.innerWidth > 600 &&
                                      <div className="col-sm-2 col-xs-2 inner_logo_block">
                                        <div className="inner_logo_div">
                                          <ArrowTooltips
                                            text={getAirlineName(
                                              el.outwardSegment[0].carrierCode
                                            )}
                                          >
                                            <img
                                              src={`/assets/img/logos/${el.outwardSegment[0].carrierCode}.gif`}
                                              className="inner_logo"
                                              alt="inner_logo"
                                            />
                                          </ArrowTooltips>
                                        </div>
                                        <div className="airport theme-search-result-airport-name">
                                          {el.outwardSegment &&
                                            `${el.outwardSegment[0].carrierCode} ${el.outwardSegment[0].flight_number}`}
                                          <p>
                                            {getAirlineName(
                                              el.outwardSegment[0].carrierCode
                                            )}
                                          </p>
                                          {el.outwardSegment?.[0]?.cabin ? <p>{showCapitlized(el.outwardSegment[0].cabin)}</p> : ""}
                                          {el.outwardSegment[0].operatingCode !==
                                            "" ? (
                                            <>
                                              <br />
                                              <p className="fit-content">
                                                Operated by <br />
                                                {getOperatingAirlineName(
                                                  el.outwardSegment[0]
                                                    .operatingCode
                                                )}
                                              </p>{" "}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    }
                                    <div className="col-sm-3 col-xs-4 inner_from theme-flight-list-items">
                                      <div className="time">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return moment(
                                              el1.departure.dateTime
                                            ).format("HH:mm");
                                          }
                                        })}
                                      </div>
                                      <div className="location">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return props.airportName.map(
                                              (el2) => {
                                                if (
                                                  el2.iata ===
                                                  el1.departure.iataCode
                                                ) {
                                                  return (
                                                    <ArrowTooltips
                                                      text={el2.airport_name}
                                                      key={i}
                                                    >
                                                      <span>{`${el2.name}(${el1.departure.iataCode})`}</span>
                                                    </ArrowTooltips>
                                                  );
                                                }
                                              }
                                            );
                                          }
                                        })}
                                      </div>
                                      <div className="date">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return moment(
                                              el1.departure.dateTime
                                            ).format("ll");
                                          }
                                        })}
                                      </div>
                                      <TerminalDetails terminal={el.outwardSegment[0]?.departure?.terminal} />
                                    </div>
                                    <div className="col-sm-3 col-xs-4 xs-0 theme-flight-list-items">
                                      <img
                                        className="inner_arrow"
                                        alt="inner_arrow"
                                        src="/assets/ficon/inner-clock.png"
                                      />
                                      <div className="inner_duration">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return el1.duration;
                                          }
                                        })}
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-xs-4 inner_to theme-flight-list-items">
                                      <div className="time">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return moment(
                                              el1.arrival.dateTime
                                            ).format("HH:mm");
                                          }
                                        })}
                                      </div>
                                      <div className="location">
                                        {el.outwardSegment.map((el1, i) => {
                                          if (i === 0) {
                                            return props.airportName.map(
                                              (el2) => {
                                                if (
                                                  el2.iata ===
                                                  el1.arrival.iataCode
                                                ) {
                                                  return (
                                                    <ArrowTooltips
                                                      text={el2.airport_name}
                                                      key={i}
                                                    >
                                                      <span>{`${el2.name}(${el1.arrival.iataCode})`}</span>
                                                    </ArrowTooltips>
                                                  );
                                                }
                                              }
                                            );
                                          }
                                        })}
                                      </div>
                                      <div className="date">
                                        {moment(
                                          el.outwardSegment[0].arrival.dateTime
                                        ).format("ll")}
                                      </div>
                                      <TerminalDetails terminal={el.outwardSegment[0]?.arrival?.terminal} />
                                    </div>
                                    {/* <div className="col-sm-1 hidden-xs">
                                      <div className="inner_icons">
                                        <ArrowTooltips text="Seat">
                                          <img
                                            className="inner_image-icons"
                                            alt="flight seat"
                                            style={{ marginBottom: "5px" }}
                                            src="/assets/ficon/flight-seat.png"
                                          />
                                        </ArrowTooltips>
                                        <ArrowTooltips text="Wi-Fi">
                                          <img
                                            alt="wi-fi"
                                            className="inner_image-icons"
                                            style={{ marginBottom: "5px" }}
                                            src="/assets/ficon/wi-fi.png"
                                          />
                                        </ArrowTooltips>
                                        <ArrowTooltips text="Video">
                                          <img
                                            alt="video"
                                            className="inner_image-icons"
                                            style={{ marginBottom: "5px" }}
                                            src="/assets/ficon/video.png"
                                          />
                                        </ArrowTooltips>
                                        <ArrowTooltips text="Power Outlet">
                                          <img
                                            alt="electrical"
                                            className="inner_image-icons"
                                            style={{ marginBottom: "5px" }}
                                            src="/assets/ficon/electrical.png"
                                          />
                                        </ArrowTooltips>
                                        <ArrowTooltips text="Snacks">
                                          <img
                                            alt="food"
                                            className="inner_image-icons"
                                            src="/assets/ficon/food.png"
                                          />
                                        </ArrowTooltips>
                                      </div>
                                    </div> */}
                                  </div>
                                  {window.innerWidth > 600 &&
                                    <Baggage bagge={addBaggageExists ? 1 : el.outwardSegment[0].bagge} />
                                  }
                                </div>
                                {el.outwardSegment.map((el1, im) => {
                                  if (im > 0) {
                                    return (
                                      <>
                                        <div className="col-md-11 width100 inner_middle-layover">
                                          <div className="row inner_layover layover-div">
                                            <div className="col-md-8 m_auto-layover m_auto_change_webonly">
                                              <img
                                                alt="inner_exchange"
                                                src="/assets/ficon/exchange.png"
                                                className="inner_exchange"
                                              />
                                              <span className="inner_changeinfo">
                                                Flight Change at{" "}
                                                {props.airportName.map(
                                                  (el2, index) => {
                                                    if (
                                                      el2.iata ===
                                                      el1.departure.iataCode
                                                    ) {
                                                      return (
                                                        <ArrowTooltips
                                                          text={
                                                            el2.airport_name
                                                          }
                                                          key={index}
                                                        >
                                                          <span>{`${el2.name}(${el1.departure.iataCode})`}</span>
                                                        </ArrowTooltips>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </span>
                                            </div>
                                            <div className="col-md-4 col-xs-12 m_auto-layover m_auto_layover_webonly">
                                              <span className="inner_layover_time">
                                                Layover of{" "}
                                                {(() => {
                                                  let startTime = new Date(
                                                    el.outwardSegment[
                                                      im - 1
                                                    ].arrival.dateTime
                                                  ).getTime();
                                                  let endTime = new Date(
                                                    el1.departure.dateTime
                                                  );
                                                  let diff = +(
                                                    endTime - startTime
                                                  );
                                                  // return moment(diff / 1000).format("HH:mm");
                                                  let d = moment
                                                    .utc(diff)
                                                    .format("H:mm ");
                                                  let formatedDate =
                                                    d.split(":");
                                                  if (
                                                    formatedDate.length === 1
                                                  ) {
                                                    return `${formatedDate[0].trim()}M`;
                                                  } else if (
                                                    formatedDate.length === 2
                                                  ) {
                                                    return `${formatedDate[0]
                                                      }h ${formatedDate[1].trim()}m`;
                                                  }
                                                })()}
                                              </span>
                                              <img
                                                src="/assets/ficon/timer.png"
                                                className="inner_timer"
                                                alt="inner_timer"
                                              />
                                            </div>
                                          </div>
                                          <div className="inner_divider"></div>
                                        </div>
                                        <div className="col-md-11 inner_detail width100">
                                          {window.innerWidth <= 600 &&
                                            <AirportDetails
                                              airLineName={el.carrierCode}
                                              gifCode={el1.carrierCode}
                                              carrierCode={el1.carrierCode}
                                              flightNumber={el1.flight_number}
                                              operatingCode={el1.operatingCode}
                                              requiredWard={el.outwardSegment}
                                              cabin={el1?.cabin}
                                              bagge={el1.bagge}
                                              addBaggageExists={addBaggageExists}
                                              getOperatingAirlineName={getOperatingAirlineName}
                                            />
                                          }
                                          <div className="row">
                                            {window.innerWidth > 600 &&
                                              <div className="col-sm-2 col-xs-2 inner_logo_block">
                                                <div className="inner_logo_div">
                                                  <ArrowTooltips
                                                    text={getAirlineName(
                                                      el1.carrierCode
                                                    )}
                                                  >
                                                    <img
                                                      src={`/assets/img/logos/${el1.carrierCode}.gif`}
                                                      className="inner_logo"
                                                      alt="inner_logo"
                                                    />
                                                  </ArrowTooltips>
                                                </div>
                                                <div className="airport theme-search-result-airport-name">
                                                  {el.outwardSegment &&
                                                    `${el1.carrierCode} ${el1.flight_number}`}
                                                  <p>
                                                    {getAirlineName(
                                                      el1.carrierCode
                                                    )}
                                                  </p>
                                                  {el1?.cabin ? <p>{showCapitlized(el1.cabin)}</p> : ""}
                                                  {el1.operatingCode !== "" ? (
                                                    <>
                                                      <br />
                                                      <p className="fit-content">
                                                        Operated by <br />
                                                        {getOperatingAirlineName(
                                                          el1.operatingCode
                                                        )}
                                                      </p>{" "}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            }
                                            <div className="col-sm-3 col-xs-4 inner_from theme-flight-list-items">
                                              <div className="time">
                                                {moment(
                                                  el1.departure.dateTime
                                                ).format("HH:mm")}
                                              </div>
                                              <div className="location">
                                                {props.airportName.map(
                                                  (el2, index) => {
                                                    if (
                                                      el2.iata ===
                                                      el1.departure.iataCode
                                                    ) {
                                                      return (
                                                        <ArrowTooltips
                                                          text={
                                                            el2.airport_name
                                                          }
                                                          key={index}
                                                        >
                                                          <span>{`${el2.name}(${el1.departure.iataCode})`}</span>
                                                        </ArrowTooltips>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </div>
                                              <div className="date">
                                                {moment(
                                                  el1.departure.dateTime
                                                ).format("ll")}
                                              </div>
                                              <TerminalDetails terminal={el1.departure?.terminal} />
                                            </div>
                                            <div className="col-sm-3 col-xs-4 xs-0 theme-flight-list-items">
                                              <img
                                                className="inner_arrow"
                                                alt="inner_arrow"
                                                src="/assets/ficon/inner-clock.png"
                                              />
                                              <div className="inner_duration">
                                                {el1.duration}
                                              </div>
                                            </div>
                                            <div className="col-sm-3 col-xs-4 inner_to theme-flight-list-items">
                                              <div className="time">
                                                {moment(
                                                  el1.arrival.dateTime
                                                ).format("HH:mm")}
                                              </div>
                                              <div className="location">
                                                {props.airportName.map(
                                                  (el2, index) => {
                                                    if (
                                                      el2.iata ===
                                                      el1.arrival.iataCode
                                                    ) {
                                                      return (
                                                        <ArrowTooltips
                                                          text={
                                                            el2.airport_name
                                                          }
                                                          key={index}
                                                        >
                                                          <span>{`${el2.name}(${el1.arrival.iataCode})`}</span>
                                                        </ArrowTooltips>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </div>
                                              <div className="date">
                                                {moment(
                                                  el1.arrival.dateTime
                                                ).format("ll")}
                                              </div>
                                              <TerminalDetails terminal={el1.arrival?.terminal} />
                                            </div>
                                            {/* <div className="col-sm-1 hidden-xs">
                                              <div className="inner_icons">
                                                <ArrowTooltips text="Seat">
                                                  <img
                                                    className="inner_image-icons"
                                                    alt="inner_image-icons"
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                    src="/assets/ficon/flight-seat.png"
                                                  />
                                                </ArrowTooltips>
                                                <ArrowTooltips text="Wi-Fi">
                                                  <img
                                                    className="inner_image-icons"
                                                    alt="inner_image-icons"
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                    src="/assets/ficon/wi-fi.png"
                                                  />
                                                </ArrowTooltips>
                                                <ArrowTooltips text="Video">
                                                  <img
                                                    className="inner_image-icons"
                                                    alt="inner_image-icons"
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                    src="/assets/ficon/video.png"
                                                  />
                                                </ArrowTooltips>
                                                <ArrowTooltips text="Power Outlet">
                                                  <img
                                                    className="inner_image-icons"
                                                    alt="inner_image-icons"
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                    src="/assets/ficon/electrical.png"
                                                  />
                                                </ArrowTooltips>
                                                <ArrowTooltips text="Snacks">
                                                  <img
                                                    className="inner_image-icons"
                                                    alt="inner_image-icons"
                                                    src="/assets/ficon/food.png"
                                                  />
                                                </ArrowTooltips>
                                              </div>
                                            </div> */}
                                          </div>
                                          {window.innerWidth > 600 &&
                                            <Baggage bagge={addBaggageExists ? 1 : el1.bagge} />
                                          }
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                            {/* return */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {el.outwardSegment[0].bagge !== 0 && (
                      <div className="st-bagg-allowance">
                        This fare includes {el.outwardSegment[0].bagge + " "}
                        checked-in luggage
                        {el.outwardSegment[0].bagge === 1 ? " of 23 kg " : " "}
                        {el.outwardSegment[0].bagge === 2 ? " of 23 kg " : " "}
                        plus 7-8 kg hand baggage per person.
                      </div>
                    )}
                    {addBaggageExists && (
                      <div className="st-bagg-allowance">
                        This fare includes 1 checked-in luggage of 23 kg plus 7-8 kg hand baggage per person.
                      </div>
                    )}
                  </div>
                  {(i + 1) % 3 === 0 ? (
                    <StaticPopup
                      title={BookedContext.SettingList.flight_detail_save_strip}
                      contactNo={BookedContext.SettingList.contact_phone}
                    />
                  ) : null}
                </>
              </Delayed>
            );
          })}
      </div>
    </>
  );
};
export default withRouter(SingleTrip);
