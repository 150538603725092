import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";

const TopHeaderNofication = (props) => {
  const [show, setShow] = useState(true);
  const [animation, setAnimation] = useState(true);

  const handleClose = (value) => {
    setAnimation(value);
    setTimeout(() => {
      setShow(value)
    }, 900)
  }

  return (
    <div className="top-header-notification">
      <Toast
        onClose={() => handleClose(false)}
        show={show}
        animation={false}
        className={`header-notification text-center ${animation ? "" : 'hide-toast'}`}
      >
        <Toast.Header></Toast.Header>
        <Toast.Body className="header-notification-body">
          {props.title}
        </Toast.Body>
      </Toast>
    </div>
  );
};
export default TopHeaderNofication;
