import React from 'react'

const CheckInBaggage = ({
  flight_details,
  addBaggage,
  addBaggageFunc,
  baggagePrice,
  baggageBasePrice
}) => {
  return (
    <>
      {(!flight_details?.bagge && !flight_details?.inwardSegment?.[0]?.bagge && !flight_details?.outwardSegment?.[0]?.bagge) ?
        <div className="custom-side add-baggage-section">
          <div className='add-baggage'>{addBaggage ? "Your check-in baggage" : "Add your check-in baggage"}</div>
          <div className="d-flex align-items-center flex-section">
            <div className={`baggage-image ${addBaggage && "baggage-image-added"}`} />
            {addBaggage ?
              <div className='upgrade-text'>
                You have selected baggage upgrade on this journey!
              </div>
              :
              <div className='baggage-option'>
                <span className='cheaper-text'>Upto 65% cheaper</span> than you would pay at the airport. Price is <span className='cheaper-text' style={{ color: "green"}}>£{baggageBasePrice}</span> for 1Pcs per passenger(excluding infants).
              </div>
            }
          </div>
          <div className="d-flex baggage-section-two">
            <div className='baggage-price'>{addBaggage ? `-£${baggagePrice}` : `+£${baggagePrice}`}</div>
            <button
              className={`add-baggage-btn ${addBaggage ? "btn btn-warning" : "btn btn-primary"}`}
              type="submit"
              onClick={(e) => addBaggageFunc(e)}
            >
              {addBaggage ? "REMOVE BAGGAGE" : "ADD BAG FOR ALL"}
            </button>
          </div>
        </div> : ""
      }
    </>
  )
}

export default CheckInBaggage