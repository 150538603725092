import React, { useContext } from "react";
import Context from "../../Context/Context";
import _ from "lodash";

export const TripSummary = (props) => {
  let bookedContext = useContext(Context);
  const AdultFares = props.adultFare;
  const ChildFares = props.childFare;
  const InfantFares = props.infantFare;
  const BookingFares = props.bookingFare;

  return !props.pricingInfo ? (
    <React.Fragment>
      <div className="card text-primary trip-summary-card">
        <div className="card-header trip-summary-card-header">
          <img src={`/assets/ficon/trip.png`} alt="trip" />
          Fare Summary
        </div>
        <div className="card-body">
          <div className="row">
            {AdultFares ? (
              <div className="col-12">
                <div className="font-weight-bold fare-title">
                  {" "}
                  Fare for {AdultFares?.FARE?.No || ""} Adult
                  {AdultFares?.FARE?.No > 1 ? "s" : ""}{" "}
                </div>
                <div>
                  <div>
                    {Object.entries(AdultFares).map(([key, item]) => (
                      <div className="mb-1 row no-gutters fare-content">
                        <div className="col-8 col-md-8">
                          <span>
                            {item.Item}{" "}
                            {item.Item.includes("Ticket") ? (
                              <span className="tax">(Inc. Fees and Tax)</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div className="col-4 col-md-4 font-weight-bold text-right ">
                          <span>£{parseFloat(item.Total).toFixed(2)}</span>
                        </div>
                        {props?.addBaggage ?
                          <>
                            <div className="col-8 col-md-8">
                              <span>
                                {AdultFares?.FARE?.No} x Adult Baggage{" "}
                                  <span className="tax">{`(£${props?.baggageBasePrice})`}</span>
                              </span>
                            </div>
                            <div className="col-4 col-md-4 font-weight-bold text-right ">
                              <span>£{parseFloat(props?.baggageBasePrice * AdultFares?.FARE?.No).toFixed(2)}</span>
                            </div>
                          </> : ""
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {ChildFares && ChildFares.FARE ? (
              <div className="col-12">
                <div className="font-weight-bold fare-title">
                  {" "}
                  Fare for {ChildFares?.FARE?.No || ""} Child
                  {ChildFares?.FARE?.No > 1 ? "ren" : ""}{" "}
                </div>
                <div>
                  <div>
                    {Object.entries(ChildFares).map(([key, item]) => (
                      <div className="mb-1 row no-gutters fare-content">
                        <div className="col-6 col-md-6">
                          <span>
                            {item.Item}{" "}
                            {item.Item.includes("Tickets") ? (
                              <span className="tax">(Inc. Fees and Tax)</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div className="col-6 col-md-6 font-weight-bold text-right ">
                          <span>£{parseFloat(item.Total).toFixed(2)}</span>
                        </div>
                        {props?.addBaggage ?
                          <>
                            <div className="col-8 col-md-8">
                              <span>
                                {ChildFares?.FARE?.No} x Child Baggage{" "}
                                  <span className="tax">{`(£${props?.baggageBasePrice})`}</span>
                              </span>
                            </div>
                            <div className="col-4 col-md-4 font-weight-bold text-right ">
                              <span>£{parseFloat(props?.baggageBasePrice * ChildFares?.FARE?.No).toFixed(2)}</span>
                            </div>
                          </> : ""
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {InfantFares && InfantFares.FARE ? (
              <div className="col-12">
                <div className="font-weight-bold fare-title">
                  {" "}
                  Fare for {InfantFares?.FARE?.No || ""} Infant
                  {InfantFares?.FARE?.No > 1 ? "s" : ""}{" "}
                </div>
                <div>
                  <div>
                    {Object.entries(InfantFares).map(([key, item]) => (
                      <div className="mb-1 row no-gutters fare-content">
                        <div className="col-6 col-md-6">
                          <span>{item.Item}</span>
                        </div>
                        <div className="col-6 col-md-6 font-weight-bold text-right ">
                          <span>£{parseFloat(item.Total).toFixed(2)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {BookingFares && BookingFares.FEE ? (
              <div className="col-12">
                <div className="font-weight-bold fare-title"> Other Fares </div>
                <div>
                  <div>
                    {Object.entries(BookingFares).map(([key, item]) => (
                      <div className="mb-1 row no-gutters fare-content">
                        <div className="col-6 col-md-6">
                          <span>{item.Item}</span>
                        </div>
                        <div className="col-6 col-md-6 font-weight-bold text-right ">
                          <span>£{parseFloat(item.Total).toFixed(2)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className="hr_divider" />

          {props?.taxPrice ? (
              <div className="font-weight-bold fare-title d-flex fees-tax-price">
                <div>Fees and Tax</div>
                <div className="tax-price-icard fare-content">£{parseFloat(props.taxPrice).toFixed(2)}</div>
              </div>
            ) : (
              ""
            )}

          <div
            className="row no-gutters total-fare"
            data-bind="with: ItinTotalFare[0].TotalFare"
          >
            <div className="col-8 col-xs-8"> Total Fare </div>
            <div className="col-4 col-xs-4">
              <h3 className="float-right font-weight-bold">
                {" "}
                £{(parseFloat(props.totalPrice) + props.taxPrice + props.baggagePrice).toFixed(2)}{" "}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="card text-primary trip-summary-card">
        <div className="card-header trip-summary-card-header">
          <img src={`/assets/ficon/trip.png`} alt="trip" />
          Fare Summary
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div>
                {props.pricingInfo.map((item) => (
                  <div className="mb-1 row no-gutters fare-content">
                    <div className="col-6 col-md-6">
                      <span>{item.Item}</span>
                    </div>
                    <div className="col-6 col-md-6 font-weight-bold text-right ">
                      <span>£{parseFloat(item.Total).toFixed(2)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr className="hr_divider" />
          <div
            className="row no-gutters total-fare"
            data-bind="with: ItinTotalFare[0].TotalFare"
          >
            <div className="col-6 col-md-6"> Total Fare </div>
            <div className="col-6 col-md-6">
              <h3 className="float-right font-weight-bold">
                £
                {props?.totalPrice ? 
                 (parseFloat(props?.totalPrice) + props.taxPrice).toFixed(2) :
                 bookedContext?.bookedFlightDetail?.data?.length && bookedContext?.bookedFlightDetail?.data[0]?.totalFare?.amount ?
                   (parseFloat(bookedContext?.bookedFlightDetail?.data[0]?.totalFare?.amount) + props.taxPrice + props.baggagePrice).toFixed(2)
                 : 0.00
                 }
              </h3>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
